import { createStore } from 'vuex'
export default createStore({
  state: {
    userinfo:{},
    fraction:null,
    total:0,
    ip:0,
    one:true,
    aoyi:false,
    presentId:null,
    dzmmtoken:null,
      // headurl:'http://192.168.3.190:8081',//正式
    headurl:'https://www.allslug.com/prod-api',//正式
    // headurl:'https://allslug.happydoit.com/prod-api', //测试
    // imgurl:'http://192.168.3.190:8081/api/' //本地调试
    imgurl:'https://www.allslug.com/prod-api/' //正式
    // imgurl:'https://allslug.happydoit.com/prod-api/' //测试
  },

  mutations: {
    
  },
  actions: {
    
  },
  modules: {
    
  }
})
