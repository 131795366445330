// import './config'
import $ from 'jquery';
var apiConfig={
    Version:1.240, 
	Date: "202208051051",
	isRelease: false,
	debugUserAgent: {
		enable: false,
//		localeStr: "zh-CN", 
		localeStr: "ja-JP", 
		plateform: "Android",
//		appVer: "257", 		// Android
		appVer: "317", 		// Android
//		localeStr: "TW", 
//		plateform: "iOS",
//		appVer: "473", 		// iOS
	},
}
/*
1.240
	1. 澧炲姞getConnectStatus() ==> 鍚屾BLE oMass鍙洟绶氭搷浣滆缃�
1.230
	1. 澧炲姞iOS鏀彺oha://_Stus command(later than v5.7.0)
1.229 @202204281526 by TonyLee
	1. 鍦╥OS 灏忕▼搴忎綔娉�, OHA_DevReady闇€Delay涓€榛炴檪闁撳啀鍛煎彨, 鍚﹀墖PlugIn鐨勬晥鏋滄渻琚搵鎺�
1.228 @202103231507
	1. 铏曠悊oha://_Stus return model name 鍚湁","鐨勫瓧涓�, 鏈彇浠ｆ垚":", 灏庤嚧isSame鍒ゆ柗閷, OHA_DevReady鏈冨鐧肩殑鍟忛
       (TGI@BLE鏈冨嚭閷�)
	2. 鏀瑰runStusCheck(), 浠ュ厤window.load鍜屾敼瀵玶unStusCheck()鍛煎彨涓嶄竴鑷�
1.227 @202102041510
	1. 铏曠悊cookieRead鐨�"default"鍙冩暩鐐虹┖鏅�, oha_api鏈冨偝鍑�"undefined"鐨勫弮鏁�, 灏庤嚧default鍊间笉姝ｇ⒑鐨勫晱椤�
1.226 @202101041726
	1. 澧炲姞wsWrite, wsRead, wsRemove 鍔熻兘, 璁撲箣鍓峯Mass 3.0 cookie閬庨暦鍟忛瑙ｆ焙銆�
1.225 @202011191528
	1. 鍦╫ha_api.isUiAlive()涓�, 鍒ゆ柗oha_timer.sync鏄惁瀛樺湪, 鍩疯涓€娆ha_timer.sync()
	2. 淇oha_api.getUiVersion()閷銆�
1.222 @202010061649
鏂板oha_api.isUiAlive(), 鎻愪緵APP纰鸿獚UI鏄惁閭勬椿钁�
1.221 @202009241558
un-mark $.removeCookie definition, 閬垮厤鐒℃硶鍙冪収鍟忛
1.220 @20200820
澧炲姞oha_api.getFwVer() command
*/
/*1.210 @20200702
淇iOS webkit 鐩稿鍟忛(鐣秈OS AppVersion>520 OHA_iOSReady()鏈枊濮嬪墠鎵€閫佺殑OHA command鏈冧笉瑕�)
*/
/*1.205 @20200617
绉婚櫎jquery.cookie.js鐨勭浉渚濇€�, 灏嘾efault.html鏀圭偤index.html
/*1.204 @20200421
鍥犳噳 iOS OHA App 浣跨敤鐨� UIWebview 瑕佽娣樻卑锛孉pp 瑕佷娇鐢ㄦ柊鐨� WKWebview锛屽師鏈� App 璺� UI 鐨� JavaScript 鎺ュ彛宸茬劇娉曞湪 WKWebview 浣跨敤锛岃鏀规柊鐨勪綔娉曪紟App 鐗堟湰 5.2.0 涔嬪緦浣跨敤鏂扮殑鏂瑰紡
鑸婄殑 锛歸indow.OHANativeApis.JSCommand(_cmdStr); 
鏂扮殑 锛歸indow.webkit.messageHandlers.JSCommand.postMessage(_cmdStr);
/*
/*1.203
淇敼 getUiVerObj() , 涓嶈ō瀹欰PP鐗堟湰鎴朠C
*/
/*1.160
	1. add setPolling & runStusCheck function
/* 
v1.130 20180627
	1. Add oha_api.setPowerStatus(_status) function
v1.110 20180622 : 
	1. Remove doPolling function, replaced with oha://_Stus in $(window).load #200ms
	2. Add return "OK" in runOhaCmd()
	3. Add readCustJson()
	
*/

function OHA_DevReady(_id, _model, _url, _uuid, _verFw, _intfType, _stus){
    console.log('88888888888888');
//    MyLog("OHA_DevReady, _model="+_model+", ohaConfig.model="+oha_api.ohaConfig.model);
	if(_model=="" || _model=="null") {
		return;
	}
	
	if(typeof _stus!=undefined) {
		oha_api.__connectStatus=_stus;
	}
//	for(var i=0;i<arguments.length;i++) {
//		console.log("OHA_DevReady, arguments["+i+"="+arguments[i])
//	}
//	console.log("OHA_DevReady, arguments.length="+arguments.length)
//	console.log("OHA_DevReady, oha_api._curDevArguments.length="+oha_api._curDevArguments.length)
	if(arguments.length==oha_api._curDevArguments.length) {
		var isSame=true;
		for(var i=0;i<arguments.length;i++) {
//				console.log("_curDevArguments["+i+"]="+oha_api._curDevArguments[i]);
//				console.log("arguments["+i+"]="+arguments[i]);
			if(oha_api._curDevArguments[i]!=arguments[i]) {
//				console.log("isSame=false!!!!!")
			   isSame=false;
//				alert("isSame=false, idx="+i+", cur="+oha_api._curDevArguments[i]+", new="+arguments[i]);
			   break;
			}
		}
		if(isSame) {
//			console.log("OHA_DevReady return ")
			return;
		}
	}
	oha_api._curDevArguments=arguments;
//	console.log("OHA_DevReady cpx")
    var _modelX=_model.split(":");
	oha_api.ohaConfig.isReadOnly=false;
    for(var i=0;i<_modelX.length;i++) {
        if(_modelX[i].toLocaleUpperCase()=="RO") {
            oha_api.ohaConfig.isReadOnly=true;
        }
    }
 	if(!oha_api.ohaConfig.isCheckModelName || (_modelX[0]==oha_api.ohaConfig.model)) {
//        MyLog("Hello, model matches")
		if(typeof arguments[3] =="string") {
	//		console.log("arguments[3]="+arguments[3]);
			oha_api.__uuid=arguments[3];
		}
		if(typeof arguments[4] !== "undefined") {
	//		console.log("arguments[3]="+arguments[3]);
			oha_api.__verFw=parseFloat(arguments[4]);
			if(isNaN(oha_api.__verFw)) {
				oha_api.__verFw=arguments[4];
			}
		}
        if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple) {
            if(oha_api.DeviceId != _id) {
                oha_api.DeviceId=_id;
				oha_api.__devPlugInP(_id, _model, arguments[2]);
                oha_api.DevPlugIn(_model, arguments[2]);
            }
        }
        else {
            oha_api.DeviceId=_id;
			oha_api.__devPlugInP(_id, _model, arguments[2]);
            oha_api.DevPlugIn(_model, arguments[2]);
        }
        if(arguments.length>2) {
			console.log("oha_api.OHA_DevReady before readRemoteJson("+arguments[2]+")");
            oha_api.readRemoteJson(arguments[2]);
        }
 	}
//	console.log("OHA_DevReady, arguments[3]="+arguments[3]);
}

function OHA_DevOff(_id){
	oha_api.__uuid=null;
	oha_api.__verFw=null;
	oha_api._curDevArguments=arguments;
	oha_api.__connectStatus=null;
	oha_api.__devPlugOutP(_id);
//    oha_api.DevPlugOut();
//    oha_api.DeviceId=-1;
}

function OHA_INTR(_id, _data) {
    if(_id==oha_api.DeviceId) {
        oha_api.OHA_INTR(_data);
    }
}
function OHA_RET(_id, _srcCmd, _recvData){
    var _srcCmd1=_srcCmd;
    if(_id==oha_api.DeviceId) {
        if(_srcCmd.indexOf("G:")==0) {
            _srcCmd1=_srcCmd.substring(2);
        }
        oha_api.OHA_RET(_srcCmd1, _recvData);
    }
}

function OHA_UiNew(_ver) {
    oha_api.UiNew(_ver);
}

function OHA_iOSReady() {
    oha_api.getIosStus()._isJsReady=true;
    oha_api.getIosStus().ready();
}
(function (factory) {
	if (typeof define === 'function' && define.amd) {
		// AMD (Register as an anonymous module)
		define(['jquery'], factory);
	} else if (typeof exports === 'object') {
		// Node/CommonJS
		module.exports = factory(require('jquery'));
	} else {
		// Browser globals
		factory($);
	}
}(function ($) {

	var pluses = /\+/g;

	function encode(s) {
		return config.raw ? s : encodeURIComponent(s);
	}

	function decode(s) {
		return config.raw ? s : decodeURIComponent(s);
	}

	function stringifyCookieValue(value) {
		return encode(config.json ? JSON.stringify(value) : String(value));
	}

	function parseCookieValue(s) {
		if (s.indexOf('"') === 0) {
			// This is a quoted cookie as according to RFC2068, unescape...
			s = s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
		}

		try {
			// Replace server-side written pluses with spaces.
			// If we can't decode the cookie, ignore it, it's unusable.
			// If we can't parse the cookie, ignore it, it's unusable.
			s = decodeURIComponent(s.replace(pluses, ' '));
			return config.json ? JSON.parse(s) : s;
		} catch(e) {}
	}

	function read(s, converter) {
		var value = config.raw ? s : parseCookieValue(s);
		return $.isFunction(converter) ? converter(value) : value;
	}

	var config = $.oha_cookie = function (key, value, options) {

		// Write

		if (arguments.length > 1 && !$.isFunction(value)) {
			options = $.extend({}, config.defaults, options);

			if (typeof options.expires === 'number') {
				var days = options.expires, t = options.expires = new Date();
				t.setMilliseconds(t.getMilliseconds() + days * 864e+5);
			}

			return (document.cookie = [
				encode(key), '=', stringifyCookieValue(value),
				options.expires ? '; expires=' + options.expires.toUTCString() : '', // use expires attribute, max-age is not supported by IE
				options.path    ? '; path=' + options.path : '',
				options.domain  ? '; domain=' + options.domain : '',
				options.secure  ? '; secure' : ''
			].join(''));
		}

		// Read

		var result = key ? undefined : {},
			// To prevent the for loop in the first place assign an empty array
			// in case there are no cookies at all. Also prevents odd result when
			// calling $.oha_cookie().
			cookies = document.cookie ? document.cookie.split('; ') : [],
			i = 0,
			l = cookies.length;

		for (; i < l; i++) {
			var parts = cookies[i].split('='),
				name = decode(parts.shift()),
				cookie = parts.join('=');

			if (key === name) {
				// If second argument (value) is a function it's a converter...
				result = read(cookie, value);
				break;
			}

			// Prevent storing a cookie that we couldn't decode.
			if (!key && (cookie = read(cookie)) !== undefined) {
				result[name] = cookie;
			}
		}

		return result;
	};

	config.defaults = {};

	$.removeCookie = function (key, options) {
		// Must not alter options, thus extending a fresh object...
		$.oha_cookie(key, '', $.extend({}, options, { expires: -1 }));
		return !$.oha_cookie(key);
	};

}));
var oha_api=(new function (_config){
    var __isDBG=true;
    var __urlCmdQ=[];
    var __toastQ=[];
    var __jsonQ=[];
	var oha_api_ready=false;
    var plugInQ=[];
    var plugOutQ=[];
	this._curDevArguments=[];
	this.__uuid=null;
	this.__verFw="NA";
    this.__stus={
        devPlugOut:-1,
        devPlugIn:0,
    };
    this.PlateFormOpt={
        Android:"Android", 
        Apple:"iOS", 
    }
    this.__devStus=this.__stus.devPlugOut;
	this.__connectStatus=null;
    var cookieParams={
        bgUrl: {
            key: "__oha_api_bgUrl"
        },
        jsonOK: {
            key: "__oha_api_jsonOK",
			default:"na", 
			options: {
				ok: "ok", 
				fail: "nok"
			}
        }
    }
    var __isUrlCmdActive=false;
    var _isToastQNotEmpty=false;
    var __urlCmdInterval;
    this.isoCountries = {
        'Afghanistan': 'AF',
        'Aland Islands': 'AX',
        'Albania': 'AL',
        'Algeria': 'DZ',
        'American Samoa': 'AS',
        'Andorra': 'AD',
        'Angola': 'AO',
        'Anguilla': 'AI',
        'Antarctica': 'AQ',
        'Antigua And Barbuda': 'AG',
        'Argentina': 'AR',
        'Armenia': 'AM',
        'Aruba': 'AW',
        'Australia': 'AU',
        'Austria': 'AT',
        'Azerbaijan': 'AZ',
        'Bahamas': 'BS',
        'Bahrain': 'BH',
        'Bangladesh': 'BD',
        'Barbados': 'BB',
        'Belarus': 'BY',
        'Belgium': 'BE',
        'Belize': 'BZ',
        'Benin': 'BJ',
        'Bermuda': 'BM',
        'Bhutan': 'BT',
        'Bolivia': 'BO',
        'Bosnia And Herzegovina': 'BA',
        'Botswana': 'BW',
        'Bouvet Island': 'BV',
        'Brazil': 'BR',
        'British Indian Ocean Territory': 'IO',
        'Brunei Darussalam': 'BN',
        'Bulgaria': 'BG',
        'Burkina Faso': 'BF',
        'Burundi': 'BI',
        'Cambodia': 'KH',
        'Cameroon': 'CM',
        'Canada': 'CA',
        'Cape Verde': 'CV',
        'Cayman Islands': 'KY',
        'Central African Republic': 'CF',
        'Chad': 'TD',
        'Chile': 'CL',
        'China': 'CN',
        'Christmas Island': 'CX',
        'Cocos (Keeling) Islands': 'CC',
        'Colombia': 'CO',
        'Comoros': 'KM',
        'Congo': 'CG',
        'Congo, Democratic Republic': 'CD',
        'Cook Islands': 'CK',
        'Costa Rica': 'CR',
        'Cote D\'Ivoire': 'CI',
        'Croatia': 'HR',
        'Cuba': 'CU',
        'Cyprus': 'CY',
        'Czech Republic': 'CZ',
        'Denmark': 'DK',
        'Djibouti': 'DJ',
        'Dominica': 'DM',
        'Dominican Republic': 'DO',
        'Ecuador': 'EC',
        'Egypt': 'EG',
        'El Salvador': 'SV',
        'Equatorial Guinea': 'GQ',
        'Eritrea': 'ER',
        'Estonia': 'EE',
        'Ethiopia': 'ET',
        'Falkland Islands': 'FK',
        'Faroe Islands': 'FO',
        'Fiji': 'FJ',
        'Finland': 'FI',
        'France': 'FR',
        'French Guiana': 'GF',
        'French Polynesia': 'PF',
        'French Southern Territories': 'TF',
        'Gabon': 'GA',
        'Gambia': 'GM',
        'Georgia': 'GE',
        'Germany': 'DE',
        'Ghana': 'GH',
        'Gibraltar': 'GI',
        'Greece': 'GR',
        'Greenland': 'GL',
        'Grenada': 'GD',
        'Guadeloupe': 'GP',
        'Guam': 'GU',
        'Guatemala': 'GT',
        'Guernsey': 'GG',
        'Guinea': 'GN',
        'Guinea-Bissau': 'GW',
        'Guyana': 'GY',
        'Haiti': 'HT',
        'Heard Island & Mcdonald Islands': 'HM',
        'Holy See (Vatican City State)': 'VA',
        'Honduras': 'HN',
        'Hong Kong': 'HK',
        'Hungary': 'HU',
        'Iceland': 'IS',
        'India': 'IN',
        'Indonesia': 'ID',
        'Iran, Islamic Republic Of': 'IR',
        'Iraq': 'IQ',
        'Ireland': 'IE',
        'Isle Of Man': 'IM',
        'Israel': 'IL',
        'Italy': 'IT',
        'Jamaica': 'JM',
        'Japan': 'JP',
        'Jersey': 'JE',
        'Jordan': 'JO',
        'Kazakhstan': 'KZ',
        'Kenya': 'KE',
        'Kiribati': 'KI',
        'Korea': 'KR',
        'Kuwait': 'KW',
        'Kyrgyzstan': 'KG',
        'Lao People\'s Democratic Republic': 'LA',
        'Latvia': 'LV',
        'Lebanon': 'LB',
        'Lesotho': 'LS',
        'Liberia': 'LR',
        'Libyan Arab Jamahiriya': 'LY',
        'Liechtenstein': 'LI',
        'Lithuania': 'LT',
        'Luxembourg': 'LU',
        'Macao': 'MO',
        'Macedonia': 'MK',
        'Madagascar': 'MG',
        'Malawi': 'MW',
        'Malaysia': 'MY',
        'Maldives': 'MV',
        'Mali': 'ML',
        'Malta': 'MT',
        'Marshall Islands': 'MH',
        'Martinique': 'MQ',
        'Mauritania': 'MR',
        'Mauritius': 'MU',
        'Mayotte': 'YT',
        'Mexico': 'MX',
        'Micronesia, Federated States Of': 'FM',
        'Moldova': 'MD',
        'Monaco': 'MC',
        'Mongolia': 'MN',
        'Montenegro': 'ME',
        'Montserrat': 'MS',
        'Morocco': 'MA',
        'Mozambique': 'MZ',
        'Myanmar': 'MM',
        'Namibia': 'NA',
        'Nauru': 'NR',
        'Nepal': 'NP',
        'Netherlands': 'NL',
        'Netherlands Antilles': 'AN',
        'New Caledonia': 'NC',
        'New Zealand': 'NZ',
        'Nicaragua': 'NI',
        'Niger': 'NE',
        'Nigeria': 'NG',
        'Niue': 'NU',
        'Norfolk Island': 'NF',
        'Northern Mariana Islands': 'MP',
        'Norway': 'NO',
        'Oman': 'OM',
        'Pakistan': 'PK',
        'Palau': 'PW',
        'Palestinian Territory, Occupied': 'PS',
        'Panama': 'PA',
        'Papua New Guinea': 'PG',
        'Paraguay': 'PY',
        'Peru': 'PE',
        'Philippines': 'PH',
        'Pitcairn': 'PN',
        'Poland': 'PL',
        'Portugal': 'PT',
        'Puerto Rico': 'PR',
        'Qatar': 'QA',
        'Reunion': 'RE',
        'Romania': 'RO',
        'Russian Federation': 'RU',
        'Rwanda': 'RW',
        'Saint Barthelemy': 'BL',
        'Saint Helena': 'SH',
        'Saint Kitts And Nevis': 'KN',
        'Saint Lucia': 'LC',
        'Saint Martin': 'MF',
        'Saint Pierre And Miquelon': 'PM',
        'Saint Vincent And Grenadines': 'VC',
        'Samoa': 'WS',
        'San Marino': 'SM',
        'Sao Tome And Principe': 'ST',
        'Saudi Arabia': 'SA',
        'Senegal': 'SN',
        'Serbia': 'RS',
        'Seychelles': 'SC',
        'Sierra Leone': 'SL',
        'Singapore': 'SG',
        'Slovakia': 'SK',
        'Slovenia': 'SI',
        'Solomon Islands': 'SB',
        'Somalia': 'SO',
        'South Africa': 'ZA',
        'South Georgia And Sandwich Isl.': 'GS',
        'Spain': 'ES',
        'Sri Lanka': 'LK',
        'Sudan': 'SD',
        'Suriname': 'SR',
        'Svalbard And Jan Mayen': 'SJ',
        'Swaziland': 'SZ',
        'Sweden': 'SE',
        'Switzerland': 'CH',
        'Syrian Arab Republic': 'SY',
        'Taiwan': 'TW',
        'Tajikistan': 'TJ',
        'Tanzania': 'TZ',
        'Thailand': 'TH',
        'Timor-Leste': 'TL',
        'Togo': 'TG',
        'Tokelau': 'TK',
        'Tonga': 'TO',
        'Trinidad And Tobago': 'TT',
        'Tunisia': 'TN',
        'Turkey': 'TR',
        'Turkmenistan': 'TM',
        'Turks And Caicos Islands': 'TC',
        'Tuvalu': 'TV',
        'Uganda': 'UG',
        'Ukraine': 'UA',
        'United Arab Emirates': 'AE',
        'United Kingdom': 'GB',
        'United States': 'US',
        'United States Outlying Islands': 'UM',
        'Uruguay': 'UY',
        'Uzbekistan': 'UZ',
        'Vanuatu': 'VU',
        'Venezuela': 'VE',
        'Vietnam': 'VN',
        'Virgin Islands, British': 'VG',
        'Virgin Islands, U.S.': 'VI',
        'Wallis And Futuna': 'WF',
        'Western Sahara': 'EH',
        'Yemen': 'YE',
        'Zambia': 'ZM',
        'Zimbabwe': 'ZW'
    };
    
	this.isoLangs = {
        'Abkhaz':'ab', 
        'Afar':'aa', 
        'Afrikaans':'af', 
        'Akan':'ak', 
        'Albanian':'sq', 
        'Amharic':'am', 
        'Arabic':'ar', 
        'Aragonese':'an', 
        'Armenian':'hy', 
        'Assamese':'as', 
        'Avaric':'av', 
        'Avestan':'ae', 
        'Aymara':'ay', 
        'Azerbaijani':'az', 
        'Bambara':'bm', 
        'Bashkir':'ba', 
        'Basque':'eu', 
        'Belarusian':'be', 
        'Bengali':'bn', 
        'Bihari':'bh', 
        'Bislama':'bi', 
        'Bosnian':'bs', 
        'Breton':'br', 
        'Bulgarian':'bg', 
        'Burmese':'my', 
        'Catalan; Valencian':'ca', 
        'Chamorro':'ch', 
        'Chechen':'ce', 
        'Chichewa; Chewa; Nyanja':'ny', 
        'Chinese':'zh', 
        'Chuvash':'cv', 
        'Cornish':'kw', 
        'Corsican':'co', 
        'Cree':'cr', 
        'Croatian':'hr', 
        'Czech':'cs', 
        'Danish':'da', 
        'Divehi; Dhivehi; Maldivian;':'dv', 
        'Dutch':'nl', 
        'English':'en', 
        'Esperanto':'eo', 
        'Estonian':'et', 
        'Ewe':'ee', 
        'Faroese':'fo', 
        'Fijian':'fj', 
        'Finnish':'fi', 
        'French':'fr', 
        'Fula; Fulah; Pulaar; Pular':'ff', 
        'Galician':'gl', 
        'Georgian':'ka', 
        'German':'de', 
        'Greek, Modern':'el', 
        'Guaran铆':'gn', 
        'Gujarati':'gu', 
        'Haitian; Haitian Creole':'ht', 
        'Hausa':'ha', 
        'Hebrew (modern)':'he', 
        'Herero':'hz', 
        'Hindi':'hi', 
        'Hiri Motu':'ho', 
        'Hungarian':'hu', 
        'Interlingua':'ia', 
        'Indonesian':'id', 
        'Interlingue':'ie', 
        'Irish':'ga', 
        'Igbo':'ig', 
        'Inupiaq':'ik', 
        'Ido':'io', 
        'Icelandic':'is', 
        'Italian':'it', 
        'Inuktitut':'iu', 
        'Japanese':'ja', 
        'Javanese':'jv', 
        'Kalaallisut, Greenlandic':'kl', 
        'Kannada':'kn', 
        'Kanuri':'kr', 
        'Kashmiri':'ks', 
        'Kazakh':'kk', 
        'Khmer':'km', 
        'Kikuyu, Gikuyu':'ki', 
        'Kinyarwanda':'rw', 
        'Kirghiz, Kyrgyz':'ky', 
        'Komi':'kv', 
        'Kongo':'kg', 
        'Korean':'ko', 
        'Kurdish':'ku', 
        'Kwanyama, Kuanyama':'kj', 
        'Latin':'la', 
        'Luxembourgish, Letzeburgesch':'lb', 
        'Luganda':'lg', 
        'Limburgish, Limburgan, Limburger':'li', 
        'Lingala':'ln', 
        'Lao':'lo', 
        'Lithuanian':'lt', 
        'Luba-Katanga':'lu', 
        'Latvian':'lv', 
        'Manx':'gv', 
        'Macedonian':'mk', 
        'Malagasy':'mg', 
        'Malay':'ms', 
        'Malayalam':'ml', 
        'Maltese':'mt', 
        'M膩ori':'mi', 
        'Marathi (Mar膩峁環墨)':'mr', 
        'Marshallese':'mh', 
        'Mongolian':'mn', 
        'Nauru':'na', 
        'Navajo, Navaho':'nv', 
        'Norwegian Bokm氓l':'nb', 
        'North Ndebele':'nd', 
        'Nepali':'ne', 
        'Ndonga':'ng', 
        'Norwegian Nynorsk':'nn', 
        'Norwegian':'no', 
        'Nuosu':'ii', 
        'South Ndebele':'nr', 
        'Occitan':'oc', 
        'Ojibwe, Ojibwa':'oj', 
        'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic':'cu', 
        'Oromo':'om', 
        'Oriya':'or', 
        'Ossetian, Ossetic':'os', 
        'Panjabi, Punjabi':'pa', 
        'P膩li':'pi', 
        'Persian':'fa', 
        'Polish':'pl', 
        'Pashto, Pushto':'ps', 
        'Portuguese':'pt', 
        'Quechua':'qu', 
        'Romansh':'rm', 
        'Kirundi':'rn', 
        'Romanian, Moldavian, Moldovan':'ro', 
        'Russian':'ru', 
        'Sanskrit (Sa峁乻k峁泃a)':'sa', 
        'Sardinian':'sc', 
        'Sindhi':'sd', 
        'Northern Sami':'se', 
        'Samoan':'sm', 
        'Sango':'sg', 
        'Serbian':'sr', 
        'Scottish Gaelic; Gaelic':'gd', 
        'Shona':'sn', 
        'Sinhala, Sinhalese':'si', 
        'Slovak':'sk', 
        'Slovene':'sl', 
        'Somali':'so', 
        'Southern Sotho':'st', 
        'Spanish; Castilian':'es', 
        'Sundanese':'su', 
        'Swahili':'sw', 
        'Swati':'ss', 
        'Swedish':'sv', 
        'Tamil':'ta', 
        'Telugu':'te', 
        'Tajik':'tg', 
        'Thai':'th', 
        'Tigrinya':'ti', 
        'Tibetan Standard, Tibetan, Central':'bo', 
        'Turkmen':'tk', 
        'Tagalog':'tl', 
        'Tswana':'tn', 
        'Tonga (Tonga Islands)':'to', 
        'Turkish':'tr', 
        'Tsonga':'ts', 
        'Tatar':'tt', 
        'Twi':'tw', 
        'Tahitian':'ty', 
        'Uighur, Uyghur':'ug', 
        'Ukrainian':'uk', 
        'Urdu':'ur', 
        'Uzbek':'uz', 
        'Venda':'ve', 
        'Vietnamese':'vi', 
        'Volap眉k':'vo', 
        'Walloon':'wa', 
        'Welsh':'cy', 
        'Wolof':'wo', 
        'Western Frisian':'fy', 
        'Xhosa':'xh', 
        'Yiddish':'yi', 
        'Yoruba':'yo', 
        'Zhuang, Chuang':'za', 
    }
	this.PowerStatus={
		Unknown:-1,
		OFF:0, 
		ON: 1
	}
	var _pwrStatus=this.PowerStatus.Unknown;
	this.setPowerStatus=function(_stus) {
		if((this.ohaConfig.plateForm==this.PlateFormOpt.Apple) || this.ohaConfig.appVer <= 292) {
			return;
		}
		var _p="F0";
		switch(_stus) {
			case this.PowerStatus.ON:
				_pwrStatus=_stus;
				_p="E1";
				break;
			case this.PowerStatus.OFF:
				_pwrStatus=_stus;
				_p="F0";
				break;
			default:
				_p=null;
		}
		if(_p==null) {
			console.log("oha_api.setPowerStatus Error!! parameter is illegal.")
			console.log("Please set oha_api.PowerStatus.ON or oha_api.PowerStatus.OFF")
		}
		else {
			return this.runOhaCmd("oha://wcmd_02_00_"+_p);
		}
	}
    function MyLog(_msg) {
        if(__isDBG) {
            console.log(_msg);
        }
    }
    var __toastCode=
        '<div class="oha_api toastViewDiv">\
            <div class="oha_api toastView">\
                <text></text>\
            </div>\
        </div>';
    $("html").append(__toastCode);
//    MyLog("oha_api version="+_config.Version);
    var __localeObj=new function(){
        var self=this;
        this.localeCode="";
        this.isReady=false;
        this.done=function(_f) {
            if(typeof _f=="function") {
                self.doneF=_f;
            }
            if(self.isReady) {
                self.doneF(self.localeCode);
            }
        }, 
        this.doneF=function(_localeCode) {
            MyLog("Locale Code="+_localeCode);
        }
    };
	
	function runStusCheck() {
//		console.log("runStusCheck, isPolling="+isPolling)
		if(isPolling) {
//			console.log("runStusCheck cp0p");
//			var _ret = oha_api.runOhaCmd("oha://_Stus");
////				console.log("oha_api oha://_Stus ret="+_ret);
//			if(_ret!==null) {
//				_ret=_ret.replace("://", "//");
//				var _retArray=_ret.split(":");
//				if(typeof(_retArray[2])!="undefined") {
//					_retArray[2]=_retArray[2].replace(/,/g, ":");
//				}
//				if(typeof(_retArray[3]) == "undefined") {
//					_retArray[3]="";
//				}
//				if(_retArray[0]!=="pause") {
//					switch(_retArray[0]) {
//						case 'true':
//							MyLog("oha_api, OHA_DevReady("+parseInt(_retArray[1])+","+_retArray[2]+","+_retArray[3].replace("//","://")+")" );
//							var _retArray1=_retArray.slice(1);
//							_retArray1[0]=parseInt(_retArray1[0]);
//							_retArray1[2]=_retArray1[2].replace("//","://");
//							OHA_DevReady.apply(this, _retArray1);
////							if(oha_api.DeviceId==-1) {
////								OHA_DevReady(parseInt(_retArray[1]), _retArray[2], _retArray[3].replace("//","://"));
////							}
//							break;
//						case 'false':
//							MyLog("runStusCheck, DevPlugOut");
//							OHA_DevOff(parseInt(_retArray[1]));
////							if(oha_api.DeviceId>=0) {
////								OHA_DevOff(parseInt(_retArray[1]));
////							}
//							break;
//						default:
//							break;
//					}
//				}
//			}
			_runStusCheck();
			setTimeout(function(){
				runStusCheck();
			}, 300);
		}
	}
	
	function _runStusCheck() {
		if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android) {
			var _ret = oha_api.runOhaCmd("oha://_Stus");
//					console.log("oha_api oha://_Stus ret="+_ret);
			if(_ret!==null) {
				_ret=_ret.replace("://", "//");
				var _retArray=_ret.split(":");
				switch(_retArray[0]) {
					case 'true':
						var _retArray1=_retArray.slice(1);
						_retArray1[0]=parseInt(_retArray1[0]);
						_retArray1[1]=_retArray1[1].replace(",",":");
						_retArray1[2]=_retArray1[2].replace("//","://");
						OHA_DevReady.apply(this, _retArray1);1
						break;
					case 'false':
						OHA_DevOff(parseInt(_retArray[1]));
					default:
						break;
				}
			}
		}
		else if((oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple) && (oha_api.getAppVer()>=570)) {
			oha_api.runOhaCmd("oha://_Stus");
		}
		
	}

	$(window).on("load", function(){
        console.log('准备执行OHA_DevReady');
        // console.log(OHA_DevReady());
//		console.log("oha_api window load")
		setTimeout(function(){
            // OHA_DevReady()
        // OHA_iOSReady()
//			if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android) {
//				var _ret = oha_api.runOhaCmd("oha://_Stus");
////					console.log("oha_api oha://_Stus ret="+_ret);
//				if(_ret!==null) {
//					_ret=_ret.replace("://", "//");
//					var _retArray=_ret.split(":");
//					switch(_retArray[0]) {
//						case 'true':
//							var _retArray1=_retArray.slice(1);
//							_retArray1[0]=parseInt(_retArray1[0]);
//							_retArray1[1]=_retArray1[1].replace(",",":");
//							_retArray1[2]=_retArray1[2].replace("//","://");
//							OHA_DevReady.apply(this, _retArray1);
//							break;
//						case 'false':
//							OHA_DevOff(parseInt(_retArray[1]));
//						default:
//							break;
//					}
//				}
//			}
			_runStusCheck();
		}, 500);
	})
	
//	this.checkDevice=function() {
//		if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android) {
//			var _ret = oha_api.runOhaCmd("oha://_Stus");
//				console.log("oha_api oha://_Stus ret="+_ret);
//			if(_ret!==null) {
//				_ret=_ret.replace("://", "//");
//				var _retArray=_ret.split(":");
//				if(typeof(_retArray[2])!="undefined") {
//					_retArray[2]=_retArray[2].replace(/,/g, ":");
//				}
//				if(typeof(_retArray[3]) == "undefined") {
//					_retArray[3]="";
//				}
//				if(_retArray[0]!=="pause") {
//					switch(_retArray[0]) {
//						case 'true':
//							MyLog("oha_api, OHA_DevReady("+parseInt(_retArray[1])+","+_retArray[2]+","+_retArray[3].replace("//","://")+")" );
//	//						OHA_DevReady(parseInt(_retArray[1]), _retArray[2], _retArray[3].replace("//","://"));
//							setTimeout(function() {
//								OHA_DevReady(parseInt(_retArray[1]), _retArray[2], _retArray[3].replace("//","://"), _retArray[4]);
//							}, 200);
//							return true;
//							break;
//						default:
//							return false;
//							break;
//					}
//				}
//			}
//			return false;
//		}
//		}
	
	
	var isPolling=false;
	this.setPolling=function(_isPolling) {
//		console.log("oha_api.setPolling("+_isPolling+")")
		isPolling=_isPolling;
		this.ready(function(){runStusCheck();})
	}

	var __readyQ=[];
	this.ready=function(_f) {
//		console.log("oha_api.ready(), oha_api_ready="+oha_api_ready)
		if(oha_api_ready) {
			_f.call();
		}
		else {
			__readyQ.push(_f);
		}
	}
	
	this.isUiAlive=function(){
//		console.log("oha_api.isUiAlive");
		if(typeof oha_timer!="undefined") {
			if(oha_timer.hasOwnProperty("sync")) {
//				console.log("oha_api.isUiAlive, oha_timer.sync()");
				oha_timer.sync();
			}
		}
		if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android) {
			return "isUiAlive:true";
		}
		else if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple) {
			return true;
		}
	}
	
	this.getConnectStatus=function() {
		return this.__connectStatus;
	}
	
	$(window).ready(function(){
    //	$(".main").css({"padding-top":($(window).height()*5)/100+"px"});
//        oha_api.getIosStus()._isJsReady=false;
        document.documentElement.style.webkitUserSelect='none';
        var uAgent=navigator.userAgent;
		if(_config.debugUserAgent.enable && !_config.isRelease) {
//			console.log("debugUserAgent")
			if(_config.debugUserAgent.plateform=="iOS") {
//				console.log("debugUserAgent, OS=iOS")
				uAgent="UserAgent=Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Mobile/14G60;oLang:"+_config.debugUserAgent.localeStr+";oVer:01;oPlateform:iOS;appVer:"+_config.debugUserAgent.appVer;
			}
			else {
//				console.log("debugUserAgent, OS=Android")
				uAgent="userAgent=Mozilla/5.0 (Linux; Android 6.0.1; VirtualBox Build/MOB31E; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/44.0.2403.119 Mobile Safari/537.36;oLangN:"+_config.debugUserAgent.localeStr+";oLang:HK;oVer:01;oPlateform:Android;appVer:"+_config.debugUserAgent.appVer;
			}
		}
//		uAgent="UserAgent=Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Mobile/14G60;oLang:TW;oVer:01;oPlateform:iOS;appVer:473";
//		uAgent="UserAgent=Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Mobile/14G60;oLang:TW;oVer:01;appVer:473";
//		uAgent="userAgent=Mozilla/5.0 (Linux; Android 6.0.1; VirtualBox Build/MOB31E; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/44.0.2403.119 Mobile Safari/537.36;oLangN:zh-HK;oLang:HK;oVer:01;oPlateform:Android;appVer:257";
    //    MyLog("userAgent="+uAgent)
    //    var _pat="/oPlateform[ ]*[^;]+!([ ]*|$)";
        var _pat=/oPlateform[ ]*:[ ]*[^; ]+(?=[ ]*([;]|$))/g;
        var _plateform=uAgent.match(_pat);
        if(_plateform!=null) {
            var _p=_plateform[_plateform.length-1];
            _p=_p.substring(_p.indexOf(":")+1).trim();
            if(_p.toLowerCase()=="android") {
                oha_api.ohaConfig.plateForm="Android";
        //        $("#titleText").css({"color":"red"});

            }
            else if(_p.toLowerCase()=="ios") {
                oha_api.ohaConfig.plateForm="iOS";
           }
        }
        _pat=/appVer[ ]*:[ ]*[0-9]+(?=[ ]*([;]|$))/g;
        var _appVer=uAgent.match(_pat);
    //    MyLog("App Version="+_appVer);
        if(_appVer!=null) {
            _p=_appVer[_appVer.length-1];
            _p=_p.substring(_p.indexOf(":")+1).trim();
             oha_api.ohaConfig.appVer=parseInt(_p);
        }
        var _vIdx=uAgent.indexOf("oVer:");
        var ohaVer=-1;
        if(_vIdx>=0) {
            ohaVer=parseInt(uAgent.substr(_vIdx+5, 2));
            if(isNaN(ohaVer)) {
                ohaVer=0;
            }
            oha_api.ohaConfig.ohaVer=ohaVer;
        }
        var ual=uAgent.length;
        var _lang=oha_api.ohaConfig.default_language;
        switch(ohaVer) {
        case 0:
            _lang=uAgent.substr(ual-2,2);
    //		alert("Lang(0)="+_lang);
        break;
        case 1:
            var _pat=/;oLang:([a-zA-Z]{2})/
            if(_pat.test(uAgent)) {
                _lang=RegExp.$1.toLowerCase();
                switch(_lang) {
                    case "us":
                        oha_api.langSet="en";
                        oha_api.countryCode="US";
                        oha_api.__localeCode="en-US";
                        break;
                    case "tw":
//                        oha_api.langSet=oha_api.langCode.TraditionChinese;
                        oha_api.langSet="zh";
                        oha_api.countryCode="TW";
                        oha_api.__localeCode="zh-TW";
                        break;
                    case "cn":
//                        oha_api.langSet=oha_api.langCode.SimpleChinese;
                        oha_api.langSet="zh";
                        oha_api.countryCode="CN";
                        oha_api.__localeCode="zh-CN";
                        break;
                    case "jp":
//                        oha_api.langSet=oha_api.langCode.SimpleChinese;
                        oha_api.langSet="ja";
                        oha_api.countryCode="JP";
                        oha_api.__localeCode="ja-JP";
                        break;
                    case "kr":
                        oha_api.langSet=oha_api.langCode.Korean;
                        oha_api.countryCode="KR";
                        oha_api.__localeCode="ko-KR";
                        break;
                    default:
                        oha_api.langSet=oha_api.langCode.English;
                        oha_api.countryCode="US";
                        oha_api.__localeCode="en-US";
                }
            }
        break;
        }
        var _pat=/;oLangN:(([a-z]{2})(?:-([A-Z]{2}))?)/
        if(_pat.test(uAgent)) {
            oha_api.__localeCode=RegExp.$1;
            oha_api.langSet=RegExp.$2;
            oha_api.countryCode=RegExp.$3;
        }
        __localeObj.isReady=true;
        __localeObj.localeCode=oha_api.__localeCode;
        __localeObj.doneF(oha_api.__localeCode);
        oha_api.initToastSize();
    //    oha_api.a();
//        if(oha_api.getPlateForm()=="iOS") {
//            setTimeout('oha_api.runOhaCmd("oha://ohaUI")',1000);        
//        }
//        else {
//            oha_api.runOhaCmd("oha://ohaUI");
//        }
        if(oha_api.ohaConfig.isUiPage) {
            oha_api.runOhaCmd("oha://ohaUI");
        }
//		if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android) {
//            if(!oha_api.ohaConfig.isStopPolling) {
//                oha_api.doPolling();
//            }
//		}
    //    MyLog("Load config.js");
//        if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android) {
//            $.getJSON("config.js").done(function(data){
//        //        MyLog("config.js load OK");
//                if(data.hasOwnProperty("Version")) {
//        //            MyLog("Read UI version="+parseFloat(data.Version));
//                    oha_api.uiVerInfo.uiVersion=parseFloat(data.Version);
//                }
//            })
//            .fail(function() {
//        //        MyLog( "config.js load error" );
//            })
//            .always(function() {
//        //        MyLog( "config.js load complete" );
//                oha_api.uiVerInfo.downloadOK=true;
//                while(oha_api.uiVerQ.length>0) {
//                    var _uiVerObj=oha_api.uiVerQ.shift();
//                    _uiVerObj.doneF(oha_api.uiVerInfo.uiVersion);
//                }
//            });
//
//            if(!oha_api.ohaConfig.isStopPolling) {
//                oha_api.doPolling();
//            }
//        }
//        else {
//            oha_api.uiVerInfo.downloadOK=true;
//            while(oha_api.uiVerQ.length>0) {
//                var _uiVerObj=oha_api.uiVerQ.shift();
//                _uiVerObj.doneF(oha_api.uiVerInfo.uiVersion);
//            }
//        }
    //    MyLog("before doPolling, isStopPolling="+oha_api.ohaConfig.isStopPolling+", Plateform="+oha_api.getPlateForm());
		while(__readyQ.length>0) {
//			console.log("ready Q hello, __readyQ.length="+__readyQ.length)
			_f=__readyQ.splice(0,1)[0];
			if(typeof(_f)=="function") {
				_f.call();
			}
			
		}
		oha_api_ready=true;
    });
    this.__localeCode=this.isoLangs.English+"-"+this.isoCountries["United States"];

    this.langSet="en";
    this.countryCode="";
    this.langCode={
        English:"en", 
        TraditionChinese:"zh-TW", 
        SimpleChinese:"zh-CN", 
        Korean:"ko", 
    }
//    $(window).ready(function(){
//       MyLog("window.ready in oha_api");
//    });
//    this.setConfig=function(_configPath) {
//
//    }
//    this.uiVerQ=[];             // Queue for get UI version
    var self=this;

    this.ohaConfig={
        apiVer:-1,
        title:"", 
        subtitle:"", 
        plateForm:"PC", 
        isCheckModelName: false, 
        html:"", 
        default_language:this.langCode.English, 
        isReadOnly:false, 
        isStopPolling:false, 
        configPath:'https://www.allslug.com/config.js', 
        isUiPage:true, 
		isDebug:false
    }
    
    this.init=function(_config){
        if(typeof _config !== undefined) {
            $.extend(true, oha_api.ohaConfig, _config);
        }
        if(oha_api.ohaConfig.hasOwnProperty("sw_ver")) {
//            oha_api.getUiVersion().uiVersion=oha_api.ohaConfig.sw_ver;
            __uiVerInfo.uiVersion=oha_api.ohaConfig.sw_ver;
        }
//        if(oha_api.ohaConfig.hasOwnProperty("config_ver")) {
//        }
    }
    
    this.getApiVer=function() {
        return _config.Version;
    }
    
    this.getLocale = function() {
//        return this.__localeCode;
        return __localeObj;
    }
    
    this.DeviceId=-1;
    
	this.__devPlugOutP=function(_id) {
		this.DevPlugOut(_id);
		this.DeviceId=-1;
        for(var i=0;i<plugOutQ.length;i++) {
            plugOutQ[i](_id);
        }
	}
	
	this.__devPlugInP=function(_id, _model, _url) {
        if(typeof arguments[1] == "string") {
            var __bgUrl=_url;
            if(__remoteJsonObj.UrlPath !== __bgUrl) {
                __remoteJsonObj.UrlPath=__bgUrl;
				oha_api.cookieRemove(cookieParams.jsonOK.key);
                __remoteJsonObj.isReady=false;
            }
        }
		if(self.getPlateForm()==self.PlateFormOpt.Apple) {
			setTimeout(function(){
				for(var i=0;i<plugInQ.length;i++) {
					plugInQ[i](_id, _model, _url);
				}
			}, 100);
		}
		else {
			for(var i=0;i<plugInQ.length;i++) {
				plugInQ[i](_id, _model, _url);
			}
		}
	}
    this.addDevPlugIn=function(_f){
        if(typeof _f=="function") {
            plugInQ.push(_f);
        }
    }
    
    this.addDevPlugOut=function(_f){
        if(typeof _f=="function") {
            plugOutQ.push(_f);
        }
    }
    
    this.DevPlugIn=function(){
        MyLog("Device is plugged in!!")
    }
    
    this.DevPlugOut=function(_id){
        MyLog("Device("+_id+") is plugged out!!")
    }
    
    this.OHA_INTR=function(_data) {
        MyLog("Interrupt data in==>"+_data);
    }
    
    this.OHA_RET=function(_srcCmd, _retData) {
        MyLog("Read command return(command="+_srcCmd+", return data="+_retData);
    }
    
    this.UiNew=function(_ver) {
        console.log("New version UI is ready to update")
    }
    
    this.initToastSize=function() {
        __x.__initSize();
    }
    
    this.getPlateForm=function() {
        return this.ohaConfig.plateForm;
    }
    
    this.getAppVer=function() {
        return this.ohaConfig.appVer;
    }
    
	this.getUUID=function(){
		if(((self.getPlateForm()==self.PlateFormOpt.Android) && (this.getAppVer()<318)) || 
		   ((self.getPlateForm()==self.PlateFormOpt.Apple) && (this.getAppVer()<500))) {
			throw "Warning!! the APP doesn't support the function oha_api.getUUID(), please update the newest APP first!";
		}
		return this.__uuid;
	}
	
	this.getFwVer=function() {
		return this.__verFw;
	}
    
    var __uiVerInfo={
        uiVersion:-1,
        isSucess: false, 
        downloadOK:false, 
    }
	
    function getUiVerObj(){
       let _self=this;
        function doneF(_d) {
            MyLog("getUiVerObj is done UiVersion:"+_d);
        };
        function failF() {
            MyLog("getUiVerObj is fail..");
        }
        this.done=function(_f){
            if(typeof _f=="function") {
                doneF=_f;
            }
            if(__uiVerInfo.downloadOK && __uiVerInfo.isSucess) { // If it's run before, return the result directly
                doneF(__uiVerInfo.uiVersion);
            }
            return _self;
        };
        this.fail=function(_f) {
            if(typeof _f=="function") {
                failF=_f;
            }
            if(__uiVerInfo.downloadOK && !__uiVerInfo.isSucess) {   // If it's run before, return the result directly
                failF();
            }
            return _self;
        }
        if(__uiVerInfo.downloadOK) {    // If is run before, ignore it.
//                this.doneF(self.uiVerInfo.uiVersion);
        }
        else {        
            console.log(self.ohaConfig.configPath);    
//                MyLog("Load config file:"+self.ohaConfig.configPath);
            $.getJSON(self.ohaConfig.configPath)
            .done(function(data){
                MyLog("config.js load OK");
                __uiVerInfo.downloadOK=true;
                __uiVerInfo.isSucess=true;
                if(data.hasOwnProperty("DateCode")) {
					__uiVerInfo.uiVersion=data.DateCode;
                    doneF(data.DateCode);
                }
                else if(data.hasOwnProperty("Version")) {
//                        MyLog("Read UI version="+parseFloat(data.Version));
					__uiVerInfo.uiVersion=data.Version;
                    doneF(data.Version);
                }
            })
            .fail(function() {
                MyLog( "config.js load error" );
                __uiVerInfo.downloadOK=true;
                __uiVerInfo.isSucess=false;
                failF();
            });
//            .always(function() {
//                    MyLog( "config.js load complete" );
//                __uiVerInfo.downloadOK=true;
//                doneF(__uiVerInfo.uiVersion);
//            });
        }
    };
    
    this.getUiVersion=function() {
        return new getUiVerObj();
    }

    this.isReadOnly=function() {
        return this.ohaConfig.isReadOnly;
    }
    
    this.getIosStus=function() {
        return __iOSStus;
    }
    
//    var __iOSStus={
//        _isJsReady:false, 
//        done:function(_f){
//            MyLog("__iOStus.done()");
//            if(typeof _f =="function") {
//                MyLog("__iOStus.done(), _f is function");
//                this.doneF=_f;
//            }
//            if(this._isJsReady) {
//                this.doneF();
//            }
//        }, 
//        doneF:function(){
//            MyLog("iOS javascript interface is Ready")
//        }
//    };

    var __iOSStus=new function(){
        this._isJsReady=false;
	    var _queue=[];
		this.push=function(_cmdObj) {
//			MyLog("$:__iOSStus.push("+_cmdObj["cmd"]+")");
			_queue.push(_cmdObj);
		}
		this.ready=function() {
			this._isJsReady=true;
			while(_queue.length>0) {
				var _cmdObj=_queue.splice(0,1)[0];
//				MyLog("$:__iOSStus get command("+_cmdObj["cmd"]+")");
				if(_cmdObj.hasOwnProperty("readyF")) {
//					_cmdObj.readyF(window.OHANativeApis.JSCommand(_cmdObj["cmd"]));
                    if(oha_api.ohaConfig.appVer >=520) {
						_cmdObj.readyF(window.webkit.messageHandlers.JSCommand.postMessage(_cmdObj["cmd"]));
                    }
                    else {
                        _cmdObj.readyF(window.OHANativeApis.JSCommand(_cmdObj["cmd"]));
                    }
				}
			}
		}
    };

    
//    this.set = function (_config, _htmlConfig) {
//        $.extend(true, this.ohaConfig, _config);
//        this.ohaConfig.html=_htmlConfig[this.ohaConfig.default_language]+"?OHA_Local=true";
//        
//    }
//    if (typeof oha_config !== 'undefined') {
//        $.extend(true, this.ohaConfig, oha_config);
//    }
//    if(typeof html_config !== 'undefined') {
//        this.ohaConfig.html=html_config[this.ohaConfig.default_language]+"?OHA_Local=true";
//    }
    //MyLog("hello, api, this.ohaConfig.html="+this.ohaConfig.html);

    function getNum(_p) {
        var _pat=/0[xX].+/;
        if(_pat.test(_p)) {
            return parseInt(_p.substr(2),16);
        }
        else {
            return parseInt(_p);
        }
    }

    this.runOhaCmd = function(_cmdStr) {
        console.log(_cmdStr);
        if(this.ohaConfig.plateForm=="Android" && this.ohaConfig.appVer >= 184) {
            console.log('安卓手机');
            console.log(typeof(OhaJsInterface));
            MyLog('OhaJsInterface.CMD('+_cmdStr+');')
			if(typeof(OhaJsInterface)!="undefined") {
                console.log('执行了');
				var _a=OhaJsInterface.CMD(_cmdStr);
	//            MyLog('runOhaCmd('+_cmdStr+")="+_a);
				return _a;
			}
        }
        else if(this.ohaConfig.plateForm=="iOS" && this.ohaConfig.appVer >459) {
    //        alert("window.OHANativeApis.JSCommand("+"_cmdStr"+")");
    console.log('苹果手机');

            if(this.ohaConfig.appVer >470) {
               console.log(oha_api.getIosStus()._isJsReady);
                // oha_api.getIosStus()._isJsReady=true;
				if(oha_api.getIosStus()._isJsReady) {
                    console.log(window.webkit.messageHandlers);
                    console.log(window.OHANativeApis);
                    if(this.ohaConfig.appVer >=520) {
                        console.log('大于520');
                        return window.webkit.messageHandlers.JSCommand.postMessage(_cmdStr);
                    }
                    else {
                        console.log('小于520');
                        return window.OHANativeApis.JSCommand(_cmdStr);
                    }
				}
				else {
					console.log("oha_api Warning!!! App is not ready for the command, push("+_cmdStr+") to queue, the return value will be missed.");
//					this.getIosStus().done(function(){
//						window.OHANativeApis.JSCommand(_cmdStr);
//	//                    return window.OHANativeApis.JSCommand(_cmdStr);
//					});
					var _cmdObj= new function(){
							var selfx=this;
							this.cmd=_cmdStr, 
							this.readyF=function(_ret) {
								console.log("run ios command:("+_cmdStr+"), return="+_ret);
							}
							this.ready=function(_f) {
								selfx.readyF=_f;
							}
						};
					this.getIosStus().push(_cmdObj);
//					return _cmdObj;
					return null;
	//                    return window.OHANativeApis.JSCommand(_cmdStr);
				}
            }
            else {
                return window.OHANativeApis.JSCommand(_cmdStr);
            }
        }
        else {
            console.log('其它手机');
            if(__isUrlCmdActive) {
                __urlCmdQ.push(_cmdStr);
            }
            else {
                __isUrlCmdActive=true;
//                __urlCmdQ.push(_cmdStr);
				if(oha_api.getPlateForm()=="PC") {
                	MyLog('Url command('+_cmdStr+');')					
				}
				else {
					window.location.href=_cmdStr;
				}
                setTimeout(function() {
                    __x.__runUrlCmd();
                }, 200);
            }
//			return "OK";
        }
        return "";
    }

    this.ohaCmd = function(_cmd) {
//        MyLog('runOhaCmd('+_cmdStr+');')
        var _n=arguments.length;
        var _cmdStr="";
        if(this.ohaConfig.plateForm==this.PlateFormOpt.Android) {
            var _isNew=(this.ohaConfig.appVer >= 259);
            if(_isNew) {
                _cmdStr="ohaN://";
            }
            else {
                _cmdStr="oha://";
            }
            for(var i=0;i<_n;i++) {
                _cmdStr+=arguments[i]
                if(i!==_n-1) {
                    if(_isNew) {
                        _cmdStr+=String.fromCharCode(0x03);
                    }
                    else {
                        _cmdStr+="_";
                    }
                }
            }
//            MyLog('ohaCmd('+_cmdStr+")");
            if(_isNew) {
                var _a=OhaJsInterface.CMD(_cmdStr);
                return _a;
            }
            else {
                return this.runOhaCmd(_cmdStr);
            }
        }
        else if(this.ohaConfig.plateForm==this.PlateFormOpt.Apple) {
            var _isNew=false;
            if(_isNew) {
                _cmdStr="ohaN://";
            }
            else {
                _cmdStr="oha://";
            }
            for(var i=0;i<_n;i++) {
                _cmdStr+=arguments[i]
                if(i!==_n-1) {
                    if(_isNew) {
                        _cmdStr+=String.fromCharCode(0x03);
                    }
                    else {
                        _cmdStr+="_";
                    }
                }
            }
            if(_isNew) {
                this.getIosStus().done(function(){
//                    return window.OHANativeApis.JSCommand(_cmdStr);
                    if(oha_api.ohaConfig.appVer >=520) {
                        return window.webkit.messageHandlers.JSCommand.postMessage(_cmdStr);
                    }
                    else {
                        return window.OHANativeApis.JSCommand(_cmdStr);
                    }
                });
            }
            else {
                return this.runOhaCmd(_cmdStr);
            }
        }
        return null;
    }
    
    this.ApiCmdWr=function(_cmd) {
        var _cmdArray=_cmd.split(":");
        var _cmdX="";
        for(i=0;i<_cmdArray.length;i++) {
            if(isNaN(parseInt(_cmdArray[i], 16))){
                MyLog("Fail:Illegal format, \""+_cmdArray[i]+"\" is not a valid HEX number!!");
                return "Fail:Illegal format, \""+_cmdArray[i]+"\" is not a valid HEX number!!";
            }
            if(i!==0) {
                _cmdX+=":";
            }
            if(i>1) {
                _cmdX+="H,"+_cmdArray[i];
            }
            else {
                _cmdX+=_cmdArray[i];
            }
        }
        var _cmdStr="oha://cw_"+this.DeviceId+"_G:"+_cmdX;
//        MyLog("apiCmdWr: "+_cmdStr);
        return this.runOhaCmd(_cmdStr);
    }

    this.ApiCmdRd=function(_cmd) {
        var _pat=/((?:0x)?[0-9a-fA-F]+):((?:0x)?[0-9a-fA-F]+):((?:0x)?[0-9a-fA-F]+)((?::(?:0x)?[0-9a-fA-F]+)*)$/g;
        if(_pat.test(_cmd)) {   // ex. oha://cr_2024_G:04:05:H,06
//    inStr=inStr.replace(/\S\S/g, function addSpace(x) {return x+" "}).trim();
//    inStr=inStr.replace(/\b\S\b/g, function addSpace(x) {return " 0"+x+" "}).trim();
//    inStr=inStr.replace(/\s+/g, " ");
//    inStr=inStr.toUpperCase();
            var _cmdNo=getNum(RegExp.$1);
            var _sCmdNo=getNum(RegExp.$2);
            var _count=getNum(RegExp.$3);
            if(oha_api.getPlateForm()=="Android") {
                return OhaJsInterface.OHA_CMDR(DeviceId, _cmdNo, _sCmdNo, _count);
            }
        }
    }
    
    this.setToastPos=function(_percent) {
        if(((oha_api.ohaConfig.plateForm=="Android") && (oha_api.ohaConfig.appVer>=199)) &&
          (oha_api.ohaConfig.plateForm=="iOS") &&(oha_api.ohaConfig.appVer>463)) {
            return;
        }

        if(typeof _percent=="undefined") {
            $(".oha_api.toastViewDiv").css({"top":"90%"});
            return;
        }
        var _p=parseInt(_percent);
        if(typeof _p !== "number") {
            MyLog("setToastPos Error! _percent="+_percent);
            return;
        }
        if(_p<0 || _p>100) {
            MyLog("setToastPos range Error! _percent="+_p);
        }
        $(".oha_api.toastViewDiv").css({"top":_p+"%"});
    }
    
    this.getMessage = function(_msg) {
        if(typeof _msg=="string") {
            return _msg;
        }
        if(typeof _msg=="object") {
			if(oha_api.__localeCode in _msg) {
				return _msg[oha_api.__localeCode];
			}
			else if(oha_api.countryCode in _msg) {
				return _msg[oha_api.countryCode];
			}
            else if(oha_api.langSet in _msg) {
                return _msg[oha_api.langSet];
            }
            else if("default" in _msg) {
                return _msg.default;
            }
            else {
                if(oha_api.ohaConfig.default_language in _msg) {
                    return _msg.oha_api.ohaConfig.default_language;
                }
                else {
                    return _msg[Object.keys(_msg)[0]];
                }
            }
        }
    }
    
    this.showToast=function(_msg) {
        var _msg1=oha_api.getMessage(_msg);
        if(((oha_api.ohaConfig.plateForm=="Android") && (oha_api.ohaConfig.appVer>=199)) ||
          (oha_api.ohaConfig.plateForm=="iOS") &&(oha_api.ohaConfig.appVer>463)) {
            this.runOhaCmd("oha://showToast_"+_msg1);
        }
        else {
            __toastQ.push(_msg1);
            if(__toastQ.length>1) {
                return;
            }
            __x.__showToast();
        //    __showToast();
        }

    }

    this.getHtml=function() {
        var _html="";
        if(oha_api.ohaConfig.html_config.hasOwnProperty(this.__localeCode)) {
            console.log("oha_api.getHtml cp0a, oha_api.__localeCode="+this.__localeCode);
            _html=this.ohaConfig.html_config[this.__localeCode];
        }
        else if(oha_api.ohaConfig.html_config.hasOwnProperty(this.countryCode))
        {
            console.log("oha_api.getHtml cp0b, oha_api.countryCode="+this.countryCode);
            _html=this.ohaConfig.html_config[this.countryCode];
        }
        else if(oha_api.ohaConfig.html_config.hasOwnProperty(this.langSet))
        {
            console.log("oha_api.getHtml cp0c, oha_api.langSet="+this.langSet);
            _html=this.ohaConfig.html_config[this.langSet];
        }
        else {
            if(oha_api.ohaConfig.html_config.hasOwnProperty(oha_api.default_language)) {
                console.log("oha_api.getHtml cp1, oha_api.ohaConfig.html_config["+oha_api.default_language+"]="+this.ohaConfig.html_config[oha_api.default_language]);
                _html=this.ohaConfig.html_config[oha_api.default_language];
            }
            else {
                console.log("oha_api.getHtml cp2, oha_api.ohaConfig.html_config["+Object.keys(this.ohaConfig.html_config)[0]+"]="+this.ohaConfig.html_config[Object.keys(this.ohaConfig.html_config)[0]]);
                _html=this.ohaConfig.html_config[Object.keys(this.ohaConfig.html_config)[0]];
            }
        }
        _html+="?OHA_Local=true";
        console.log("oha_api.getHtml end, _html="+_html);
        return _html;
    }

    this.getTitle=function() {
        var _title="";
        if(this.ohaConfig.title.hasOwnProperty(oha_api.__localeCode)) {
            _title=this.ohaConfig.title[oha_api.__localeCode];
        }
        else if(this.ohaConfig.title.hasOwnProperty(oha_api.countryCode)) {
            _title=this.ohaConfig.title[oha_api.countryCode];
        }
        else if(this.ohaConfig.title.hasOwnProperty(oha_api.langSet)) {
            _title=this.ohaConfig.title[oha_api.langSet];
        }
        else {
            if(this.ohaConfig.title.hasOwnProperty(oha_api.default_language)) {
                _title=this.ohaConfig.title[oha_api.default_language];
            }
            else {
                _title="undefined";
            }
        }
//        switch(oha_api.langSet) {
//            case oha_api.langCode.TraditionChinese:
//                _title=this.ohaConfig.title.tw;
//                break;
//            case oha_api.langCode.SimpleChinese:
//                _title=this.ohaConfig.title.cn;
//                break;
//            case oha_api.langCode.English:
//                _title=this.ohaConfig.title.en;
//                break;
//            case oha_api.langCode.Korean:
//                _title=this.ohaConfig.title.kr;
//                break;
//        }
        return _title;
    }

    this.getSubTitle=function() {
        var _subTitle="";
        if(this.ohaConfig.title.hasOwnProperty(oha_api.__localeCode)) {
            _subTitle=this.ohaConfig.subtitle[oha_api.__localeCode];
        }
        else if(this.ohaConfig.title.hasOwnProperty(oha_api.countryCode)) {
            _subTitle=this.ohaConfig.subtitle[oha_api.countryCode];
        }
        else if(this.ohaConfig.title.hasOwnProperty(oha_api.langSet)) {
            _subTitle=this.ohaConfig.subtitle[oha_api.langSet];
        }
        else {
            _subTitle=this.ohaConfig.subtitle[oha_api.default_language];
        }
            if(this.ohaConfig.subtitle.hasOwnProperty(oha_api.default_language)) {
                _subTitle=this.ohaConfig.subtitle[oha_api.default_language];
            }
            else {
                _subTitle="undefined";
        }
//        switch(oha_api.langSet) {
//            case oha_api.langCode.TraditionChinese:
//                _html=this.ohaConfig.subtitle.tw;
//                break;
//            case oha_api.langCode.SimpleChinese:
//                _html=this.ohaConfig.subtitle.cn;
//                break;
//            case oha_api.langCode.English:
//                _html=this.ohaConfig.subtitle.en;
//                break;
//            case oha_api.langCode.Korean:
//                _html=this.ohaConfig.subtitle.kr;
//                break;
//        }
        return _subTitle;
    }

    this.setUiAlpha=function(_alpha) {
        if(oha_api.getPlateForm()=="iOS") {
            $("html").css({"opacity":parseFloat(_alpha)})
            return;
        }
        if(typeof _alpha == "undefined") {
            this.runOhaCmd("oha://alpha_0.8");
            return;
        }
        if(typeof(_alpha)=="number") {
            if(_alpha <=1 && _alpha >=0) {
                this.runOhaCmd("oha://alpha_"+_alpha)
            }
            else {
                self.showToast("Alpha value is not correct("+_alpha+")");
            }
        }
        else {
            self.showToast("Alpha value is not a number("+_alpha+")");
        }
    }

	function ohaUriEncode(_s) {
		var _ret= encodeURIComponent(_s);

		_ret=_ret.replace(new RegExp('_', 'g'), String.fromCharCode(0x03));
		return _ret;
	}
	
	function ohaUriDecode(_s) {
		var _ret= decodeURIComponent(_s);
        console.log(_ret);
		_ret=_ret.replace(new RegExp(String.fromCharCode(0x03), 'g'), "_");
		return _ret;
	}
	
    this.cookieWrite=function(_key, _data, _path) {
        if(__cookieCmdOk()) {
			_key=ohaUriEncode(_key);
			_data=ohaUriEncode(_data);
            console.log('oha://cookie_write_'+_key+"_"+_data);
            this.runOhaCmd('oha://cookie_write_'+_key+"_"+_data);
        }
        else {
            if(typeof _path=="undefined") {
            	$.oha_cookie(_key, _data, {expires:365});
            }
            else {
                $.oha_cookie(_key, _data, {expires:365, path:_path});
            }
        }
    }

    this.cookieRead=function(_key, _default) {
        if(__cookieCmdOk()) {
			 _key=ohaUriEncode(_key);
			if(typeof _default=="undefined") {
                console.log('第二步');
				return this.runOhaCmd('oha://cookie_read_'+_key);
				
			}
            console.log('有');
             _ret=this.runOhaCmd('oha://cookie_read_'+_key+"_"+_default);
			if(_ret.length==0) {
                console.log('第三步');
				return _default;
			}
			_ret=ohaUriDecode(_ret);
            return _ret;
        }
        else {
            var _c=$.oha_cookie(_key);

            if(_c==null) {
                _c=_default;
            }
            return _c;
        }
    }

	this.wsWrite=function(_key, _data) {
		if(typeof localStorage =="undefined") {
			this.cookieWrite(_key, _data);
		}
		else {
			localStorage.setItem(_key, _data);
		}
	}

	this.wsRead=function(_key, _default) {
		if(typeof localStorage =="undefined") {
			return this.cookieRead(_key, _default);
		}
		else {
			var _data=localStorage.getItem(_key);
			if(_data==null) {
				return _default;
			}
		}
	}

	this.wsRemove=function(_key) {
		if(typeof localStorage =="undefined") {
			this.cookieRemove(_key);
		}
		else {
			localStorage.removeItem(_key);
		}
	}

    this.cookieRemove=function(_key, _path) {
        if(__cookieCmdOk()) {
            this.runOhaCmd('oha://cookie_remove_'+_key);
        }
        else {
            if(typeof _path=="undefined") {
                $.removeCookie(_key);
            }
            else {
                $.removeCookie(_key, {path:_path});

            }
        }
    }

    this.cookieRemoveAll=function() {
        if(__cookieCmdOk()) {
            this.runOhaCmd('oha://cookie_removeAll');
        }
        else {
            for(var _x in $.oha_cookie()) {
                $.removeCookie(_x);
            }
        }
    }
    
//    var pollingT=null;
//    this.doPolling=function(_enable) {
//        if(typeof _enable=="undefined") {
//            _enable=true;
//        }
////        MyLog("doPolling("+_enable+")")
//    //    MyLog("enable="+_enable);
//        if(pollingT !==null) {
//            clearTimeout(pollingT);
//            pollingT=null;
//        }
//        if(!_enable) {
//            return;
//        }
//        pollingT=setTimeout(function(){
//    //        MyLog("doPolling(), stus="+oha_api.__devStus);
//            var _ret = oha_api.runOhaCmd("oha://_Stus");
////            MyLog("oMassNew oha://status ret="+_ret);
//            if(_ret==null) {
//                oha_api.doPolling();
//                return;
//            }
//            _ret=_ret.replace("://", "//");
//            var _retArray=_ret.split(":");
//			if(typeof _retArray[3] == "undefined") {
//				_retArray[3]="";
//			}			
//            if(_retArray[0]!=="pause") {
//                switch(_retArray[0]) {
//                    case 'true':
//                        if(oha_api.__devStus!==oha_api.__stus.devPlugIn) {
//                            MyLog("oha_api.polling, run OHA_DevReady("+_retArray[1]+","+_retArray[2]+","+_retArray[3].replace("//","://")+")")
//                            oha_api.__devStus=oha_api.__stus.devPlugIn;
//                            OHA_DevReady(parseInt(_retArray[1]), _retArray[2], _retArray[3].replace("//","://"));
//                        }
//                        break;
//                    case 'false':
//    //                    oha_api.DevPlugOut();
//                         if(oha_api.__devStus!==oha_api.__stus.devPlugOut) {
//                            MyLog("oha_api.polling, run OHA_DevOff("+parseInt(_retArray[1])+")")
//                            oha_api.__devStus=oha_api.__stus.devPlugOut;
//                            OHA_DevOff(parseInt(_retArray[1]));
//                        }
//                       break;
//                    default:
//                        break;
//                }
//                oha_api.doPolling();
//            }
//        }, 300);
//    }

    
    this.checkOhaString=function(_str) {
        if(_str.includes("oha_sensor")) {
            var _ohaSensorPat=/(?:oha_sensor)[ ]+([^=]+)=[ ]*([^ ]+)[ ]+([^ ]+)[ ]*[(]([^()]+)[)],(.*)$/;
            if(_ohaSensorPat.test(_str)) {
                alert("oha_sensor string pass!!\n\tSensorName="+RegExp.$1+"\n\tSensorValue="+RegExp.$2+"\n\tSensorUnit="+RegExp.$3+"\n\tRawData="+RegExp.$4+"\n\tDescription="+RegExp.$5);
            }
            else {
                alert("oha_sensor string fail!")
            }
        }
//        var _ohaSensorPat=/(?:oha_sensor)[ ]+([^=]+)=[ ]*([^ ]+)[ ]+([^ ]+)[ ]*[(]([^()]+)[)],(.*)$/;
//        var _ohaHttpPat=/^[ ]*(?:oha_http)[ ]*(.*)$/;

    }

    this.addJson=function(_json) {
        __jsonQ.push(_json);
    }

//    this.addJson=function(_json) {
//        getJsonObj.doneF(_json);
//    }

//    this.getJson=function(_key) {
//        var _json=null;
//        if(__jsonQ.hasOwnProperty(_key)) {
//            _json=__jsonQ[_key];
//            delete __jsonQ[_key];
//            return _json;
//        }
//    }
    var __configJsonObj={
        isReady: false,
        isSuccess:false, 
        JsonData: {}, 
        FailMsg: "", 
        done: function(_jdata) {
            
        }, 
        fail: function(_msg) {
            
        }
    };
    
	this.readCustJson=function(_file, _doneF){
		var _retObj=new function(){
			this.isLocalDone=false;
			this.localJdata={};
			this.localFailMsg="";
			this.isRemoteDone=false;
			this.remoteJdata={};
			this.remoteFailMsg="";
			this.done=function(_f) {
				this.doneF=_f;
				return this;
			}
			this.fail=function(_f) {
				this.failF=_f;
				return this;
			}
			this.doneF=function(_jdata) {
				MyLog("readCustJson("+_file+") is done");
			}
			this.failF=function(_msg) {
				MyLog("readCustJson("+_file+") is fail, msg="+_msg);
			}
		}
		// Remote Json
		if(__remoteJsonObj.UrlPath=="") {
			_retObj.isRemoteDone=true;
			_retObj.remoteJdata={};
			if(_retObj.isLocalDone) {
				this.doneF(_retObj.localJdata);
			}
		}
		else {
			readJson(_file, true, __remoteJsonObj.UrlPath)
			.done(function(_jdata){
				_retObj.isRemoteDone=true;
				_retObj.remoteJdata=_jdata;
				if(_retObj.isLocalDone) {
					_retObj.doneF($.extend(_retObj.localJdata, _retObj.remoteJdata));
				}
			})
			.fail(function(_msg){
				_retObj.isLocalDone=true;
				_retObj.remoteFailMsg=_msg;
				if(_retObj.isLocalDone) {
					_retObj.doneF($.extend(_retObj.localJdata, _retObj.remoteJdata));
				}
			})
			.run();
		}
		// Local Json
		readJson(_file)
		.done(function(_jdata){
			_retObj.isLocalDone=true;
			_retObj.localJdata=_jdata;
			if(_retObj.isRemoteDone) {
				_retObj.doneF($.extend(_retObj.localJdata, _retObj.remoteJdata));
			}
		})
		.fail(function(_msg){
			_retObj.isLocalDone=true;
			_retObj.localFailMsg=_msg;
			if(_retObj.isRemoteDone) {
				_retObj.doneF($.extend(_retObj.localJdata, _retObj.remoteJdata));
			}
		})
		.run();
		return _retObj;
	}
    
    this.readLocalJson=function(_doneF, _failF, _isReload) {
        if(typeof _doneF == "function") {
            __configJsonObj.done=_doneF;
        }
        else {
            if(typeof _doneF !== "undefined") {
                MyLog("Warning in oha_api.readLocalJson!! _doneF is not a function")
            }
        }
//        __configJsonObj.done=_doneF;
        if(typeof _failF == "function") {
            __configJsonObj.fail=_failF;
        }
        else {
            if(typeof _failF !== "undefined") {
                MyLog("Warning in oha_api.readLocalJson!! _failF is not a function")
            }
        }
        if(typeof _isReload !== "boolean") {
            MyLog("isReload = false")
            _isReload=false;
        }
        if(__configJsonObj.isReady && !_isReload) {
            if(__configJsonObj.JsonData !==null) {
                __configJsonObj.done(__configJsonObj.JsonData);
            }
            else if(FailMsg!=="") {
                __configJsonObj.fail(__configJsonObj.FailMsg);
            }
        }
        else {
            readJson("oha_controller_options.txt")
            .done(function(_jdata){
                __configJsonObj.JsonData=_jdata;
                __configJsonObj.isReady=true;
                 __configJsonObj.isSuccess=true;
                __configJsonObj.done(_jdata);
            })
            .fail(function(_msg){
                __configJsonObj.FailMsg=_msg;
                __configJsonObj.isReady=true;
				__configJsonObj.JsonData=null;
                __configJsonObj.fail(_msg);
            })
            .run();
        }
    }
    
    var __remoteJsonObj={
        isReady: false,
        UrlPath: "", 
        JsonData: {}, 
        FailMsg: "", 
        done: function(_jdata) {
            
        }, 
        fail: function(_msg) {
            
        }
    };
    this.readRemoteJson=function(_urlPath, _doneF, _failF) {
        if(typeof _doneF == "function") {
            __remoteJsonObj.done=_doneF;
        }
        else {
            if(typeof _doneF !== "undefined") {
                MyLog("Warning in oha_api.readRemoteJson!! _doneF is not a function")
            }
        }
        if(typeof _failF == "function") {
            __remoteJsonObj.fail=_failF;
        }
        else {
            if(typeof _failF !== "undefined") {
                MyLog("Warning in oha_api.readRemoteJson!! _failF is not a function")
            }
        }
        if((typeof _urlPath !== "string") || (_urlPath.length==0)) {
            var _msg="No remote URL";
            __remoteJsonObj.FailMsg=_msg;
            __remoteJsonObj.isReady=true;
            __remoteJsonObj.fail(_msg);
            return;
        }
        if((_urlPath.length>0) && (_urlPath !== __remoteJsonObj.UrlPath)) {
            __remoteJsonObj.isReady=false;
            __remoteJsonObj.UrlPath=_urlPath;
        }
        if(__remoteJsonObj.isReady && (_urlPath==__remoteJsonObj.UrlPath)) {
            if(__remoteJsonObj.JsonData !==null) {
                __remoteJsonObj.done(__remoteJsonObj.JsonData, false);
            }
            else if(__remoteJsonObj.FailMsg!=="") {
                __remoteJsonObj.fail(__remoteJsonObj.FailMsg, false);
            }
        }
        else {
			var _isOK=oha_api.cookieRead(cookieParams.jsonOK.key, cookieParams.jsonOK.default);
			if(_isOK == cookieParams.jsonOK.options.fail) {
				__remoteJsonObj.FailMsg="Json is not OK before";
				__remoteJsonObj.isReady=true;
				__remoteJsonObj.JsonData=null;
				__remoteJsonObj.fail(_msg);
//				if(__combinedJsonObj.isReady) {
//					__combinedJsonObj.isReady=false;
//					self.readJson();
//				}
			}
			else {
				readJson("oha_controller_options.js", true, _urlPath)
				.done(function(_jdata){
					__remoteJsonObj.JsonData=_jdata;
					__remoteJsonObj.isReady=true;
					__remoteJsonObj.done(_jdata);
					if(__combinedJsonObj.isReady) {
						__combinedJsonObj.isReady=false;
						self.readJson();
					}
					oha_api.cookieWrite(cookieParams.jsonOK.key, cookieParams.jsonOK.options.ok);
				})
				.fail(function(_msg){
					 __remoteJsonObj.FailMsg=_msg;
					__remoteJsonObj.isReady=true;
					__remoteJsonObj.JsonData=null;
					__remoteJsonObj.fail(_msg);
//					if(__combinedJsonObj.isReady) {
//						__combinedJsonObj.isReady=false;
//						self.readJson();
//					}
					oha_api.cookieWrite(cookieParams.jsonOK.key, cookieParams.jsonOK.options.fail);
				})
				.run();
			}
        }
//        oha_api.cookieWrite(cookieOptions.remoteJson.key, _url);
    }
    
    this.getJson=function(){
        var _json=__jsonQ[0];
        __jsonQ.splice(0,1);
        return _json;
    }
    
    function get_hostname(url) {
        var m = url.match(/^http:\/\/[^/]+/);
        return m ? m[0] : null;
    }

   //var jsonQ=[];
    function jsonObj(_fileName, _isRemote, _remoteUrl){
        var self=this;
        if(typeof _isRemote=="undefined") {
            _isRemote=false;
        }
        MyLog("readJson("+_fileName+", "+_isRemote+")");
        this.done=function(_f) {
            if(typeof _f == "function") {
                this.doneF=_f;
            }
            return self;
        };
        this.doneF=function(_jdata){
			MyLog("jsonObj.doneF");
        }
        this.fail=function(_f) {
            if(typeof _f == "function") {
//                MyLog("$$:jsonObj.fail(), assign failF");
                self.failF=_f;
            }
            return self;
        };
        this.failF=function(_msg){
			MyLog("jsonObj.failF");
        };
        this.run=function(){
        if(_isRemote){
            _url=_remoteUrl+"/"+_fileName+"?Cache=false";
            $.getScript(_url)
              .done(function( script, textStatus ) {
    //            MyLog( textStatus );
				var _jdata=oha_api.getJson();
				if(typeof _jdata=="object") {
                	self.doneF(_jdata);
				}
				else {
					self.failF(_url+" is not a correct json file");
				}

              })
              .fail(function( jqxhr, settings, exception ) {
                self.failF(_url+" load fail.");
            });
        }
        else {
            _url=_fileName+"?Cache=false";
            MyLog("before $.ajax, _url="+_url);
            $.ajax({                // Read Local JSON
              url: _url,
              type: "GET",
              dataType: "json",
              success: function(Jdata) {
                  MyLog("jsonObj read "+_url+" success!!")
                  self.doneF(Jdata);
              },

              error: function() {
                  MyLog("jsonObj read "+_url+" fail!!msg="+arguments[1])
                  self.failF(_url+" load fail.");
              }
            });
        }
        }
        var _hostName=window.location.hostname;
        if(_isRemote) {
            if(_hostName==get_hostname(_remoteUrl)) {
                _isRemote=false;
            }
        }

    };

    var __combinedJsonObj={
        isReady: false,
        JsonData: {}, 
        done: function(_jdata) {
            
        }, 
    };

    function readJson(_fileName, _isRemote, _remoteUrl) {
        var _obj=new jsonObj(_fileName, _isRemote, _remoteUrl);
        return _obj;
    }
    
    this.readJson=function(_doneF){
//		MyLog("$:oha_api.readJson cp0")
        if(typeof _doneF == "function") {
            __combinedJsonObj.done=_doneF;
        }
//        else if(typeof _doneF !== "undefined"){
//            MyLog("Warning in oha_api.readJson!! _doneF is not a function")
//            return;
//    	}
        if(__combinedJsonObj.isReady) {     // Ready before
            __combinedJsonObj.done(__combinedJsonObj.JsonData);
        } else if(__configJsonObj.isReady && __remoteJsonObj.isReady) {
            __combinedJsonObj.isReady=true;
            __combinedJsonObj.JsonData=__configJsonObj.JsonData;
            $.extend(true, __combinedJsonObj.JsonData, __remoteJsonObj.JsonData);
            __combinedJsonObj.done(__combinedJsonObj.JsonData);
        }
        else {
            if(!__configJsonObj.isReady) {
                self.readLocalJson(function(_jdata) {
                    MyLog("oha_api.readJson(), readLocalJson OK");
                    if(__remoteJsonObj.isReady) {
                        $.extend(true, __combinedJsonObj.JsonData, _jdata);
                        __combinedJsonObj.isReady=true;
                        __combinedJsonObj.done(__combinedJsonObj.JsonData);
                    }
                    else {
                        __combinedJsonObj.JsonData=_jdata;
                    }
                }, function(_msg){  // fail message
                    if(__remoteJsonObj.isReady) {
                        __combinedJsonObj.isReady=true;
                        __combinedJsonObj.done(__combinedJsonObj.JsonData);
                    }
                    else {
                        __combinedJsonObj.JsonData={};
                    }
                });
            }
            else {
                __combinedJsonObj.JsonData=__configJsonObj.JsonData;
            }
            if(!__remoteJsonObj.isReady) {
//                MyLog("$$: __remoteJsonObj.isReady=false;")
                self.readRemoteJson(__remoteJsonObj.UrlPath, 
                function(_jdata, _isUpdate) {      // Success function
                    MyLog("oha_api.readJson(), readRemoteJson OK, URL="+__remoteJsonObj.UrlPath);
					if(typeof _isUpdate=="undefined") {
						_isUpdate=true;
					}
                    if(__configJsonObj.isReady) {
                        $.extend(true, __combinedJsonObj.JsonData, _jdata);
                        __combinedJsonObj.isReady=true;
						if(_isUpdate) {
                        	__combinedJsonObj.done(__combinedJsonObj.JsonData);
						}
                    }
                    else {
                        __combinedJsonObj.JsonData=_jdata;
//                        __combinedJsonObj.done(__combinedJsonObj.JsonData);
                    }
                }, 
                function(_msg, _isUpdate) {        // fail function
					MyLog("oha_api.readJson(), readRemoteJson Fail, ("+_msg+")");
                    if(__configJsonObj.isReady) {
						if(!__combinedJsonObj.isReady) {
							__combinedJsonObj.isReady=true;
							if(_isUpdate) {
								__combinedJsonObj.done(__combinedJsonObj.JsonData);
							}
						}
                    }
                    else {
                        __combinedJsonObj.JsonData={};
                    }
                });
            }
            else {
                __combinedJsonObj.JsonData=__remoteJsonObj.JsonData;
            }
            
        }
    }
    
//	var appCmdSet={};
	var appCmdCallBack=[];
	this.runAppCmd=function(_cmd) {
//		if(appCmdSet.hasOwnProperty(_cmd)) {
//			var _args=[];
//			for(var i=1;i<arguments.length;i++) {
//				_args.push(arguments[i]);
//			}
//			appCmdSet[_cmd].apply(this, _args);
//		}
//		else {
//			console.log("Error in oha_api.runAppCmd!!"+_cmd+" is not defined!!");
//		}
		for(var i=0;i<appCmdCallBack.length;i++) {
			appCmdCallBack[i](_cmd);
		}
	}
    
	this.registerAppCmd=function(_callBack) {
		appCmdCallBack.push(_callBack);
	}
	
//	this.setAppCmd=function(_cmd, _cmdFunc) {
//		appCmdSet[_cmd]=_cmdFunc;
//	}
    
    function __cookieCmdOk () {
        return ((self.ohaConfig.plateForm=="Android") && (self.ohaConfig.appVer > 189));
    }

   let __x=new function () {
        var self=this;
        this.__showToast=function() {
            if(__toastQ.length==0) {
                return;
            }
            var _msg=__toastQ[0]
            $(".oha_api.toastViewDiv").stop();
            $(".oha_api.toastView").text(_msg);
            $(".oha_api.toastViewDiv").fadeIn(500);
            setTimeout(function() {
        //        $(".oha_api.toastViewDiv").fadeOut(1000, function(){
        //            __toastQ.splice(0,1);
        //            __showToast();
        //        });
                $(".oha_api.toastViewDiv").fadeOut(1000);
                __toastQ.splice(0,1);
                self.__showToast();
            }, 2000);

        }

        this.__initSize=function () {
            var _w=$(window).width();
//            $(".oha_api.toastViewDiv").css({"font-size":0.04*_w+"px"});
            $(".oha_api.toastViewDiv").css({"font-size":"4vmin"});
//            $(".oha_api.toastView").css({"border-radius":0.02*_w+"px"});
            $(".oha_api.toastView").css({"border-radius":"2vmin"});
        }

        this.__runUrlCmd=function() {
            if(__urlCmdQ.length==0) {
                __isUrlCmdActive=false;
            }
            else {
                var _cmd=__urlCmdQ[0];
                __urlCmdQ.splice(0,1);
				if(oha_api.getPlateForm()!=="PC") {
					window.location.href=_cmd;
				}
				else {
					MyLog('Url command('+_cmd+');')
				}
                setTimeout(function() {
                    self.__runUrlCmd();
                }, 200);
            }
        }
    }
    $.extend(true, this.ohaConfig, _config);
    __remoteJsonObj.UrlPath=this.cookieRead(cookieParams.bgUrl.key, "");   
}(apiConfig))
console.log('调用oha-api');

//if(typeof oha_config!=="undefined") {
//    $.extend(true, oha_api.ohaConfig, oha_config);
//}
//var scriptTag = document.createElement('script');
//    scriptTag.type="text/javascript";
//    scriptTag.src = oha_api.ohaConfig.api_path+"jquery.cookie.js";
//    document.head.appendChild(scriptTag);
$(".oha_api.toastView").css({"background-color":"black", "color":"white", "position":"fixed", "font-family":"'Arial', sans-serif", 
                    "word-wrap":"break-word", "left":"10%", "width":"80%", "border-radius":0.03*$(window).width()});
$(".oha_api.toastViewDiv").css({"display":"none", "position":"fixed", "position":"fixed", "width":"100%", 
                    "left":"0", "top":"90%", "text-align":"center", "font-size":0.04*$(window).width(), "z-index":"99999"});
var _oha_key="__oha_ws_exist";
if($.oha_cookie(_oha_key)==null)  {
	if(localStorage.getItem(_oha_key)!=null) {
		localStorage.clear();
	}encodeURIComponent
	$.oha_cookie(_oha_key, "true", {expires:365})
	localStorage.setItem(_oha_key, "true");
}
export default  oha_api