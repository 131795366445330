import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/home/index'
import login from '../views/home/login'
// tabbar
const tabbarList = [
    {
        path: '/', //首页
        name: Home,
        component: Home,
        // component: () => import('../views/home/shoesdetail.vue'),
        // name: login,
        // component: login,
    },
    {
        path: '/api/index/default.html', //首页
        name: Home,
        component: Home,
        meta: {
            title: "爱芯科技",
            tabbar:true,
        },
    },
    
    {
        path: '/login',//登录
        name: 'login',
        component: () => import('../views/home/login'),
        meta: {
            title: "爱芯科技",
            keepAlive: true,
            
        },
    },
      
    {
        path: '/my',//我的
        name: 'my',
        component: () => import('../views/my/index'),
        meta: {
            title: "爱芯科技",
            tabbar:true,
            keepAlive: true
        },
    },
    {
        path: '/mall',//商城
        name: 'mall',
        component: () => import('../views/mall/index'),
        meta: {
            title: "爱芯科技",
            tabbar:true,
            keepAlive: true
        },
    },
    {
        path: '/information',//资料
        name: 'information',
        component: () => import('../views/information/index'),
        meta: {
            title: "爱芯科技",
            tabbar:true,
            keepAlive: true
        },
    },
      
    {
        path: '/home/shezhi',//设置
        name: 'shezhi',
        component: () => import('../views/home/shezhi'),
        meta: {
            title: "爱芯科技",
            keepAlive: true
        },
    },
    {
        path: '/zhuce',//注册
        name: 'zhuce',
        component: () => import('../views/home/zhuce'),
        meta: {
            title: "爱芯科技",
            keepAlive: true
        },
    },
    {
        path: '/home/shoesdetail',//鞋子详情
        name: 'shoesdetail',
        component: () => import('../views/home/shoesdetail'),
        meta: {
            title: "爱芯科技",
            
        },
    },
    {
        path: '/home/electrondetail',//电子面膜详情
        name: 'electrondetail',
        component: () => import('../views/home/electrondetail'),
        meta: {
            title: "爱芯科技",
        },
    },
    {
        path: '/home/facedetail',//贴片详情
        name: 'facedetail',
        component: () => import('../views/home/facedetail'),
        meta: {
            title: "爱芯科技",
        },
    },
    {
        path: '/home/massagedetail',//按摩详情
        name: 'massagedetail',
        component: () => import('../views/home/massagedetail'),
        meta: {
            title: "爱芯科技",
        },
    },
    {
        path: '/information/detail',//文章详情
        name: 'detail',
        component: () => import('../views/information/detail'),
        meta: {
            title: "爱芯科技",
        },
    },
    {
        path: '/home/yszc',//隐私政策
        name: 'yszc',
        component: () => import('../views/home/yszc'),
        meta: {
            title: "爱芯科技",
        },
    },
    {
        path: '/my/setting',//设置
        name: 'setting',
        component: () => import('../views/my/setting'),
        meta: {
            title: "爱芯科技",
        },
    },
    {
        path: '/my/userinfo',
        name: 'userinfo',
        component: () => import('../views/my/userinfo'),
        meta: {
            title: "修改个人信息",
        },
    },
    {
        path: '/home/password',
        name: 'password',
        component: () => import('../views/home/password'),
        meta: {
            title: "爱芯科技",
        },
    },
    {
        path: '/my/updatepassword',
        name: 'updatepassword',
        component: () => import('../views/my/updatepassword'),
        meta: {
            title: "爱芯科技",
        },
    },
    
    
]

const routes = [
    ...tabbarList,
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    console.log(next);
    console.log(from);
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next()
});

export default router
