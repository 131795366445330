import {createApp, VueElement,Vue} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 权限
import {setDrective} from '@/directives/has'
// 格式化样式
import '@/assets/css/base.css'
// rem适配
import "lib-flexible"
//引入vant
import {Toast,Popup} from "vant";
import vant from "vant";
import footerBar from "../src/views/home/footer.vue";

// 函数样式 toast & dialog
import 'vant/es/dialog/style';
import 'vant/es/toast/style';
import $ from 'jquery';
import Vconsole from 'vconsole';



window.$ = $;
window.$store = store;
window.$router = router
window.$Toast = Toast;
// Vue.component("footerBar", footerBar);
// let vConsole = new Vconsole();
// export default vConsole
const app = createApp(App);
app.use(vant).use(setDrective).use(store).use(router).mount('#app')
