import axios from "axios";
import {Toast} from "vant";

Toast.allowMultiple();
// 请求地址
axios.defaults.baseURL = process.env.VUE_APP_URL;
//允许跨域携带cookie信息
axios.defaults.withCredentials = true;
//设置超时
axios.defaults.timeout = 150000;

axios.interceptors.request.use(
    (config) => {
        // if (sessionStorage.getItem('token')) {
        //     config.data.token = sessionStorage.getItem('token')
        // }
        // 存储code
        // if (config.url == "/api/user/wxLogin" && config.data.code) {
        //     sessionStorage.setItem("_zzcc-code", config.data.code);
        // }
        //
        // if (config.url != "/api/user/wxLogin" || config.url != "/api/shutOff/getStatus") {
        //     // 存储openid
        //     config.headers.openid = sessionStorage.getItem("_zzcc-openid");
        //     config.headers.code = sessionStorage.getItem("_zzcc-code");
        // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            // if (response.config.url == "/api/user/wxLogin") {
            //     sessionStorage.setItem("_zzcc-openid", response.data.data.openid);
            //     sessionStorage.setItem("_zzcc-userId", response.data.data.id);
            // }
            // if (response.data.code == 500 && response.data.msg == "请先登录") {
            //     sessionStorage.removeItem("_zzcc-userId");
            //     router.push({
            //         path: "/",
            //     });
            // }
            // console.log("response", response.data);

            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        console.log(error);
    }
);
export default {
    /**
     * @param {String} url
     * @param {Object} data
     * @param headers
     * @returns Promise
     */
    post(url, data, params, headers) {
        console.log(localStorage.getItem('dzmmtoken'));
        return new Promise((resolve, reject) => {
            const toastTow = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                duration: 30000,
            });
            axios({
                method: "post",
                url,
                data,
                params,
                headers:{
                    'content-type': 'multipart/form-data',
                        'Authorization':localStorage.getItem('dzmmtoken')
                },
            })
                .then((res) => {
                    resolve(res.data);
                    toastTow.clear();
                    console.log(res)
                    if (res.data.code === 200) {
                    } else {
                        Toast(res.data.msg)
                    }
                })
                .catch((err) => {
                    reject(err);
                    toastTow.clear();
                });
        });
    },

    get(url, data, headers) {
        const toastTow = Toast.loading({
            message: "加载中...",
            forbidClick: true,
            duration: 2000,
        });
        return new Promise((resolve, reject) => {
            axios({
                method: "get",
                url,
                params: data,
                headers:{
                     'content-type': 'multipart/form-data',
                    'Authorization':localStorage.getItem('dzmmtoken')
            },
            })
                .then((res) => {
                    console.log(res);
                    resolve(res.data);
                    if (res.data.code == 200) {
                        toastTow.clear();
                    } else {
                        Toast(res.data.msg);
                    }
                })
                .catch((err) => {
                    reject(err);
                    toastTow.clear();
                });
        });
    },
};
