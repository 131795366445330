import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive } from "vue";
const _hoisted_1 = {
  id: "app"
};
const _hoisted_2 = ["src"];
import { reactive, ref, watch } from "vue";
import { useRouter } from 'vue-router';
export default {
  __name: 'App',
  setup(__props) {
    const active = ref(0);
    const active2 = ref(0);
    const icon = {
      active: require('./img/index2.png'),
      inactive: require('./img/index1.png')
    };
    const icon2 = {
      active: require('./img/mall2.png'),
      inactive: require('./img/mall1.png')
    };
    const icon3 = {
      active: require('./img/information2.png'),
      inactive: require('./img/information1.png')
    };
    const icon4 = {
      active: require('./img/my2.png'),
      inactive: require('./img/my1.png')
    };
    const isTabShow = true;
    const router = useRouter();
    // 当前选择的tab
    // const active = ref(0);
    //tabbar
    const navTabBarList = reactive([{
      path: '/api/index/default.html',
      // 路径
      icon: null,
      // 图标
      isActive: 0,
      // 当前激活项
      active: require('./img/index2.png'),
      // 激活
      inactive: require('./img/index1.png'),
      //未激活
      name: '遥控' // 名字
    },
    // {
    //   path: '/mall',
    //   icon: 'setting-o',
    //   isActive: 1,
    //   active:  require('./img/mall2.png'), // 激活
    //   inactive: require('./img/mall1.png'), //未激活
    //   name: '商城'
    // },
    {
      path: '/information',
      icon: 'setting-o',
      isActive: 2,
      active: require('./img/information2.png'),
      inactive: require('./img/information1.png'),
      name: '资料'
    }, {
      path: '/my',
      icon: 'setting-o',
      isActive: 3,
      active: require('./img/my2.png'),
      inactive: require('./img/my1.png'),
      name: '我的'
    }]);
    // 是否展示路由
    const showTabBar = ref(true);
    const isKeep = ref();
    watch(() => router.currentRoute.value, router => {
      console.log(router);
      isKeep.value = router.meta.keepAlive;
      showTabBar.value = navTabBarList.some(item => {
        return item.path === router.path;
      });
    }, {
      deep: false,
      // 是否采用深度监听
      immediate: false // 首次加载是否执行
    });

    // window.location.href = window.location.href.replace('#/', '')

    function getParams(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      console.log(window.location.search);
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return r[2];
      }
      return null;
    }
    // console.log(getParams('token'))
    const changeNavTabBarList = number => {
      active.value = number;
    };
    return (_ctx, _cache) => {
      const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
      const _component_van_tabbar = _resolveComponent("van-tabbar");
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [showTabBar.value ? (_openBlock(), _createBlock(_component_van_tabbar, {
        key: 0,
        route: "",
        "active-color": "#ee0a24"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tabbar, {
          modelValue: active.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => active.value = $event)
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navTabBarList, item => {
            return _openBlock(), _createBlock(_component_van_tabbar_item, {
              key: item.name,
              to: item.path
            }, {
              icon: _withCtx(props => [_createElementVNode("img", {
                src: props.active ? item.active : item.inactive
              }, null, 8, _hoisted_2)]),
              default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.name), 1)]),
              _: 2
            }, 1032, ["to"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      })) : _createCommentVNode("", true), isKeep.value ? (_openBlock(), _createBlock(_component_router_view, {
        key: 1
      }, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_router_view, {
        key: 2
      }))]);
    };
  }
};