import "core-js/modules/es.array.push.js";
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { home, usetime } from "../../api/application/index";
import "../../utils/jquery-3.3.1.min.js";
import "../../utils/jquery.cookie.js";
import oha_api from "../../utils/oha_api";
export default {
  data() {
    return {};
  },
  mounted() {
    console.log(oha_api);
    console.log('dzmmtoken', sessionStorage.getItem('dzmmtoken'));
    this.demoshow = true;
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      // this.$router.go(-1)
      location.reload();
    }
    setTimeout(() => {
      oha_api.runOhaCmd("omass://stop");
      oha_api.runOhaCmd("oha://unlock_" + oha_api.DeviceId);
      oha_api.setPowerStatus(oha_api.PowerStatus.OFF);
      console.log('sessionStorage', sessionStorage.getItem('dzmmtoken'));
      console.log('localStorage', localStorage.getItem('dzmmtoken'));
      if (sessionStorage.getItem('dzmmtoken')) {
        localStorage.setItem('dzmmtoken', sessionStorage.getItem('dzmmtoken'));
      }
      if (!localStorage.getItem('dzmmtoken')) {
        $Toast("未登录请先登录！");
        setTimeout(() => {
          this.$router.replace({
            path: "/login"
          });
        }, 10);
        return;
      } else {
        console.log("有用户数据");
        console.log("dzmmtoken", localStorage.getItem("dzmmtoken"));
        if (localStorage.getItem("startTime")) {
          console.log("有初始时间");
          let time = new Date();
          let endTime = this.timestampToTime(time.toLocaleString("en-US", {
            hour12: false
          }).split(" "));
          this.updatetime(endTime);
        }
      }
    }, 200);
    // window.OHA_DevReady = this.OHA_DevReady
    window['OHA_DevReady'] = () => {
      this.OHA_DevReady();
    };
    window['OHA_DevOff'] = () => {
      this.OHA_DevOff();
    };
    window['OHA_INTR'] = () => {
      this.OHA_INTR();
    };
    window['OHA_RET'] = () => {
      this.OHA_RET();
    };
    window['OHA_UiNew'] = () => {
      this.OHA_UiNew();
    };
    window['OHA_iOSReady'] = () => {
      this.OHA_iOSReady();
    };
  },
  methods: {
    OHA_DevReady(_id, _model, _url, _uuid, _verFw, _intfType, _stus) {
      console.log('88888888888888');
      //    MyLog("OHA_DevReady, _model="+_model+", ohaConfig.model="+oha_api.ohaConfig.model);
      if (_model == "" || _model == "null") {
        return;
      }
      if (typeof _stus != undefined) {
        oha_api.__connectStatus = _stus;
      }
      //	for(var i=0;i<arguments.length;i++) {
      //		console.log("OHA_DevReady, arguments["+i+"="+arguments[i])
      //	}
      //	console.log("OHA_DevReady, arguments.length="+arguments.length)
      //	console.log("OHA_DevReady, oha_api._curDevArguments.length="+oha_api._curDevArguments.length)
      if (arguments.length == oha_api._curDevArguments.length) {
        var isSame = true;
        for (var i = 0; i < arguments.length; i++) {
          //				console.log("_curDevArguments["+i+"]="+oha_api._curDevArguments[i]);
          //				console.log("arguments["+i+"]="+arguments[i]);
          if (oha_api._curDevArguments[i] != arguments[i]) {
            //				console.log("isSame=false!!!!!")
            isSame = false;
            //				alert("isSame=false, idx="+i+", cur="+oha_api._curDevArguments[i]+", new="+arguments[i]);
            break;
          }
        }
        if (isSame) {
          //			console.log("OHA_DevReady return ")
          return;
        }
      }
      oha_api._curDevArguments = arguments;
      //	console.log("OHA_DevReady cpx")
      var _modelX = _model.split(":");
      oha_api.ohaConfig.isReadOnly = false;
      for (var i = 0; i < _modelX.length; i++) {
        if (_modelX[i].toLocaleUpperCase() == "RO") {
          oha_api.ohaConfig.isReadOnly = true;
        }
      }
      if (!oha_api.ohaConfig.isCheckModelName || _modelX[0] == oha_api.ohaConfig.model) {
        //        MyLog("Hello, model matches")
        if (typeof arguments[3] == "string") {
          //		console.log("arguments[3]="+arguments[3]);
          oha_api.__uuid = arguments[3];
        }
        if (typeof arguments[4] !== "undefined") {
          //		console.log("arguments[3]="+arguments[3]);
          oha_api.__verFw = parseFloat(arguments[4]);
          if (isNaN(oha_api.__verFw)) {
            oha_api.__verFw = arguments[4];
          }
        }
        if (oha_api.getPlateForm() == oha_api.PlateFormOpt.Apple) {
          if (oha_api.DeviceId != _id) {
            oha_api.DeviceId = _id;
            oha_api.__devPlugInP(_id, _model, arguments[2]);
            oha_api.DevPlugIn(_model, arguments[2]);
          }
        } else {
          oha_api.DeviceId = _id;
          oha_api.__devPlugInP(_id, _model, arguments[2]);
          oha_api.DevPlugIn(_model, arguments[2]);
        }
        if (arguments.length > 2) {
          console.log("oha_api.OHA_DevReady before readRemoteJson(" + arguments[2] + ")");
          oha_api.readRemoteJson(arguments[2]);
        }
      }
      //	console.log("OHA_DevReady, arguments[3]="+arguments[3]);
    },

    OHA_DevOff(_id) {
      console.log('这个是OHA_UiNew，线被拔出了');
      oha_api.__uuid = null;
      oha_api.__verFw = null;
      oha_api._curDevArguments = arguments;
      oha_api.__connectStatus = null;
      oha_api.__devPlugOutP(_id);
      //    oha_api.DevPlugOut();
      //    oha_api.DeviceId=-1;
    },

    OHA_INTR(_id, _data) {
      if (_id == oha_api.DeviceId) {
        oha_api.OHA_INTR(_data);
      }
    },
    OHA_RET(_id, _srcCmd, _recvData) {
      var _srcCmd1 = _srcCmd;
      if (_id == oha_api.DeviceId) {
        if (_srcCmd.indexOf("G:") == 0) {
          _srcCmd1 = _srcCmd.substring(2);
        }
        oha_api.OHA_RET(_srcCmd1, _recvData);
      }
    },
    OHA_UiNew(_ver) {
      console.log('这个是OHA_UiNew');
      oha_api.UiNew(_ver);
    },
    OHA_iOSReady() {
      console.log('这个是OHA_iOSReady');
      oha_api.getIosStus()._isJsReady = true;
      oha_api.getIosStus().ready();
    },
    updatetime(endTime) {
      usetime({
        startTime: localStorage.getItem("startTime"),
        endTime,
        id: localStorage.getItem("parentId")
      }).then(res => {
        console.log(res);
        localStorage.removeItem("startTime");
        localStorage.removeItem("parentId");
      });
    },
    godetail(index) {
      localStorage.setItem("parentId", index);
      if (index == 1) {
        this.$router.push({
          path: "/home/facedetail",
          query: {
            parentId: index
          }
        });
        return;
      }
      if (index == 2) {
        this.$router.push({
          path: "/home/massagedetail",
          query: {
            parentId: index
          }
        });
        return;
      }
      if (index == 3) {
        this.$router.push({
          path: "/home/electrondetail",
          query: {
            parentId: index
          }
        });
        return;
      }
      if (index == 4) {
        this.$router.push({
          path: "/home/shoesdetail",
          query: {
            parentId: index
          }
        });
      }
    },
    timestampToTime(times) {
      let time = times[1];
      let mdy = times[0];
      mdy = mdy.split("/");
      let month = parseInt(mdy[0]);
      let day = parseInt(mdy[1]);
      let year = parseInt(mdy[2]);
      return year + "-" + month + "-" + day + " " + time;
    }
  }
};