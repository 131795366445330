const domPermission = {
    mounted: (el, bindings) => {
        // console.log('el', el)
        // console.log('bindings', bindings)

    }
}

export function setDrective(app) {
    app.directive('hasPermission', domPermission)
}
