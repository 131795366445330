import axios from "@/utils/request";

let {post,get} = axios;
//登录
export const login = (data) => {
    return get(`user/login`, data);
};
//获取验证码
export const sendCode = (data) => {
    return get(`user/sendCode`, data);
};
//获取资料列表分类
export const dataControllertitle = (data) => {
    return get(`dataController/index`, data);
};
//获取资料列表
export const dataController = (params) => {
    return get(`dataController/articleList`, params);
};
//获取资料详情
export const dataControllerdetail = (params) => {
    return get(`dataController/article`,params);
};
//获取用户信息
export const userinfo = () => {
    return get(`user/index`);
};
//注册
export const register = (data) => {
    return get(`user/register`, data);
};
//首页
export const home = (data) => {
    return get(`remoteControl/index`, data);
};
//点赞
export const upvote = (data) => {
    return get(`dataController/upvote`, data);
};
//记录使用时间
export const usetime = (data) => {
    return get(`user/use`, data);
};
//记录使用时间
export const patternlist = params => {
    return get(`remoteControl/pattern`, params);
};
//修改个人信息
export const updateuserinfo = (data) => {
    return get(`/user/update`, data);
};
//账号密码登录
export const loginByPassword = (data) => {
    return get(`/user/loginByPassword`, data);
};
//修改密码
export const updatePassword = (data) => {
    return get(`/user/updatePassword`, data);
};
//修改头像
export const avatar = (data) => {
    return get(`/user/avatar`, data);
};






